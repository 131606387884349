/* Root */
:root {
    --font-family-main: 'Roboto', sans-serif;
    --font-family-title: 'Roboto Slab', sans-serif;
    --color-primary: #a30039;
    --color-alternative: #ba2056;
    --color-primary-contrast: #f3a4c0;
    --color-harmonic: #f0e9eb;
    --color-text: #545454;
    --color-text-secondary: #a6a6a6;
    --color-contrast: #d9d9d9;
    --color-contrast-alternative: #afafaf;
    --color-background-primary: #f2f2f2;
    --color-background-contrast: #fff;
    --box-shadow: 0px 0px 20px 0px #000 6;
    --border-radius: 15px;
}
body {
    font-family: var(--font-family-main) !important;
}
h1, h2, h3, h4, h5, h6 {
    font-weight: 700 !important;
}
/*modal plantillas*/
tr.plantilla__detalles {
    padding: 0 !important;
}
tr.plantilla__detalles:hover {
    background-color: transparent !important;
}
.plantilla__detalles td {
    display: flex;
    gap: 3rem;
    background-color: #f2f2f2;
    padding: 1rem !important;
    border-radius: 10px;
}
.plantilla__detalles td button {
    flex: 0 1 auto;
    width: auto;
    background-color: none;
    border: none;
    border-radius: 5px;
    padding: 0.5rem;
    margin-left: 1rem;
    transition: background-color 0.3s;
    cursor: pointer;
}
.plantilla__detalles td button:hover {
    background-color: #e0dfdf;
}
.plantilla__detalles td button svg {
    height: 18px;
}
.plantilla__detalles td ul {
    flex: 1 1 auto;
}
.plantilla__detalles td ul, .plantilla__detalles td li {
    margin: 0;
    padding: 0;
}
.modalasociados .cont_tabla_plantilla td:first-child {
    text-align: center !important;
}
.capadetalles ul li {
    text-align: center;
}
.modalasociados .capadetalles ul li span {
    font-weight: 600;
    margin: 0;
}
.modalasociados .cabecera_filtro {
    background: #e9e9e9 a3;
    padding: 8px !important;
    border-radius: 5px;
}
.modalasociados .cabecera_filtro .bordebtn p {
    font-weight: 600 !important;
}
.modalasociados .cabecera_filtro div {
    padding: 0 !important;
}
.modalasociados .cerrar_lateral button {
    width: 99%;
    border-radius: 3px 3px 3px 3px;
    font-size: 12px !important;
    padding: 3px 0 !important;
}
.modalasociados .aplicarbtn {
    margin-right: 1%;
    background: #a30039 !important;
    color: #fff !important;
}
.modalasociados .cerrarbtn {
    background: #767676 !important;
    color: #fff !important;
}
.modalasociados .cerrar_lateral {
    float: left;
    width: 48%;
}
.pdright2 {
    margin-right: 4%;
}
.modalasociados .checks {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 8px;
    overflow: auto;
    height: 100px;
    align-items: flex-start;
}
.modalasociados .cont_buscador input {
    width: 100% !important;
    border: 0;
    border-radius: 3px;
    padding: 0;
    text-indent: 5px;
    height: 20px;
}
.capadetalles ul {
    text-align: left;
    margin-top: 25px;
    list-style: none;
}
.modalasociados .cabeceratabla img, .cont_tabla_plantilla img {
    width: 20px;
}
.modalasociados .cabeceratabla p {
    color: #000;
    font-weight: normal;
    font-size: 12px;
    margin: 0 !important;
}
.modalasociados .cabeceratabla {
    background: #fff !important;
    color: #595959;
    padding-right: 10px !important;
    border-bottom: 0.05em solid #ccc !important;
    margin-bottom: 5px;
}
.modalasociados .pd15 {
    padding: 0 0;
}
.modalasociados .cont_tabla_plantilla td:first-child button {
    float: none;
    padding: 0;
}
.modalasociados button.btn_noactive span {
    content: url("../img/icos-11.svg");
    width: 20px;
    height: 20px;
    display: block;
    margin-right: 25px;
}
.modalasociados button.btn_active span {
    content: url("../img/icos-11rojo.svg");
    width: 20px;
    height: 20px;
    display: block;
    margin-right: 25px;
}
.modalasociados .col-xs-10 {
    padding: 0 15px 0 0 !important;
}
.modalasociados .col-xs-2 {
    height: -webkit-fill-available;
    border-right: none !important;
}
.modalasociados .contname {
    color: #000;
    font-weight: normal;
    font-size: 12px;
    text-align: left;
   /* padding: 15px 0;
    */
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.modalasociados .tabladatos td {
    color: #000;
    font-weight: normal;
    font-size: 12px;
}
.modalasociados .capadetalles {
    background: #f2f2f2 !important;
    border: 0 !important;
}
.modalasociados .tabladatos ul {
    float: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modalasociados .tabladatos td {
    width: 7% !important;
}
.modalasociados .tabladatos td:nth-child(1) {
    width: 7% !important;
}
.modalasociados .tabladatos td:nth-child(2) {
    width: 13% !important;
}
.modalasociados .tabladatos td:nth-child(1) img {
    width: 12px !important;
    height: 12px !important;
    margin-top: 5px !important;
}
.modalasociados .tabladatos td:nth-child(1) p {
    margin-bottom: 5px !important;
}
.modalasociados .cont_tabla_plantilla td:nth-child(6) img {
    margin-left: 10px !important;
}
.modalasociados .cont_tabla_plantilla td:nth-child(7) img {
    margin-left: 15px !important;
}
.modalasociados .cont_tabla_plantilla td:nth-child(8) img {
    margin-left: 15px !important;
}
.modalasociados .sinformato {
    width: 100%;
    margin-top: 5px;
    font-size: 12px !important;
    margin-bottom: 15px;
}
.modalasociados .tabladatos td {
   /* padding-left: 10px;
    */
    width: 10%;
    vertical-align: top;
    line-height: 16px;
}
.modalasociados .cont_tabla_plantilla td:first-child {
    text-align: center !important;
}
.modalasociados td {
    padding: 3px 0;
}
.modalasociados tr {
    border: none !important;
}
.capadetalles button {
    border: none !important;
    outline: 0 !important;
    background: none !important;
    font-size: 1em !important;
}
.capadetalles button {
    float: right !important;
    margin-right: 0 !important;
}
.capadetalles img {
    margin: 0 !important;
    padding: 0 !important;
}
.capadetalles ul {
    text-align: left !important;
    margin-top: 25px !important;
    list-style: none !important;
}
/*modales 23/12/2023*/
.custom-modal.modalasociados h4 {
    font-size: 24px;
    font-weight: 900;
    color: #a30039;
    text-transform: uppercase;
}
.custom-modal.modalasociados h3 {
    font-size: 16px;
    margin-bottom: 10px;
    color: #555;
    font-style: italic;
}
.custom-modal.modalasociados {
    display: flex;
    align-items: center;
}
.custom-modal.modalasociados .modal-main {
    max-width: 1080px;
    padding: 25px;
}
.custom-modal.modalasociados .container_modal {
    padding: 25px !important;
}
.cierramodal {
    right: -15px;
    position: absolute;
    top: -70px;
}
.modalasociados .inputscampos {
    display: flex;
    margin-bottom: 0;
}
.modalasociados .inputscampos div {
    margin: 0 10px 0 0;
    font-size: 16px;
    display: flex;
    align-items: center;
}
.cierramodal {
    position: absolute;
    right: 0;
    top: 0;
}
.modalasociados .borrarcampoimg {
    width: 18px;
    height: auto;
    margin: 0;
    align-self: right;
}
.modalasociados .inputscampos span {
    margin-right: 0;
    margin-left: 10px;
}
.modalasociados .inputscampos2, .modalasociados .inputscampos {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 20px;
    margin-bottom: 20px;
}
.modalasociados .inputscampos2 input {
    margin: 0 5px 0 8px;
    width: 17px;
    height: 17px;
    display: none;
}
.modalasociados .inputscampos2 label, .modalasociados .inputscampos div {
    background-color: #ddd;
    padding: 1rem 2rem;
    margin: 0;
    border-radius: var(--bs-border-radius);
    font-size: 12px;
    font-weight: 600;
    transition-duration: 0.3s;
    transform: translateY(0);
}
.modalasociados .inputscampos2 label:hover, .modalasociados .inputscampos div:hover {
    cursor: pointer;
    background-color: #ccc;
    transform: translateY(-2px);
}
.modalasociados .contbutton .btn {
    padding: 1rem 2rem !important;
    font-weight: 600;
}
.modalasociados .inputscampos2 input:checked + label {
    background-color: #a30039;
    color: white;
}
.modalasociados .inputscampos2 input:checked + label:hover {
    background-color: #b82357;
}
.modalasociados .contbutton button {
    padding: 6px 10px !important;
    background: #a30039;
    color: #fff;
    font-size: 14px;
}
.modalasociados .contbutton {
    margin: 5px 0;
}
.modalasociados .contbutton.ultimo {
    display: flex;
    justify-content: flex-end;
}
.modalasociados .ordenalista button {
    margin: 0 10px 0 10px;
}
.modalasociados .agrupacion h5 {
    margin-bottom: 0;
    margin-right: 10px;
    font-size: 16px;
    font-weight: normal;
    text-wrap: nowrap;
}
.modalasociados .agrupacion {
    display: flex;
    align-items: center;
    padding: 9px;
    font-size: 16px;
    background: #e2e2e2;
    padding-left: 15px;
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
}
.modalasociados .ordenalista button {
    color: #fff;
    background: #a30039;
    padding: 8px 12px !important;
    font-size: 14px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    margin: 0 10px 0 0;
}
.pd15 {
    margin: 0 0 10px 0;
}
.modalasociados .contagrupa {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    font-weight: 400;
}
.modalasociados button.borraragrupacion {
    display: flex;
    align-items: center;
}
.modalasociados button.borraragrupacion img {
    width: 20px;
    height: 20px;
    display: block;
    margin: 0 0 0 5px;
}
.modalasociados .listacampos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 15px;
}
.modalasociados .camposelec {
    padding: 3px;
    font-size: 16px;
    margin: 5px;
    cursor: pointer;
}
.modalasociados .camposelec:hover {
    opacity: 0.5;
}
.modalasociados .listacampos input {
    padding: 0 0 0 6px;
    margin: 0;
    height: 30px;
    font-size: 16px !important;
    -webkit-padding-end: 30px;
    border: 1px solid #000 !important;
    color: #000 !important;
}
.modalasociados .col-xs-8 {
    padding-left: 0;
}
.modalasociados .col-xs-4 {
    padding-left: 0;
}
.nexoagrupacion span {
    background: #a30039;
    color: #fff;
    align-items: center;
    margin: 0 3px;
    border-radius: 3px 3px 3px 3px;
    width: 24px;
    display: flex;
    justify-content: center;
}
.ordenalista {
    font-size: 16px;
}
.ordenalista h3 {
    margin: 10px 0 15px 0;
}
.modalasociados .listacampos .buscar {
    width: 20px;
    right: 30px;
    position: relative;
}
.modalasociados .rojo {
    color: #a30039;
    padding: 0;
    font-weight: 600;
    margin: 0;
}
.grey {
    background: #767676 !important;
}
.grey2 {
    background: #767676 !important;
}
.ordenaflex {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.modalasociados .ordenaflex .btn.grey2.borrarsolo img {
    width: 20px;
    height: 20px;
    margin: 0 0 0 5px;
}
.modalasociados .ordenalista .ordenaflex button {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    justify-content: center;
    margin: 0 0 0 10px;
}
.modalasociados button.borraragrupacion {
    margin: 0 10px 0 0 !important;
}
.nexocampos span {
    background: #a30039;
    color: #fff;
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
    min-width: 25px;
    text-align: center;
    cursor: pointer;
}
.cond {
    display: flex;
}
.cond .buscar {
    margin-top: 5px;
}
.modalasociados button.eliminartodos {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    justify-content: center;
}
.modalasociados button.eliminartodos img {
    width: 20px;
    height: 20px;
    margin: 0 0 0 5px;
}
.modalasociados button.quitartodos {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 16px;
    justify-content: center;
}
.modalasociados button.quitartodos img {
    width: 20px;
    height: 20px;
    margin: 0 0 0 5px;
}
.modalasociados .ordenalista.pd15 {
    display: flex;
}
.modalasociados button {
    font-size: 12px !important;
}
.modalasociados .ordenalista div {
    display: flex;
    margin: 3px 0 6px 0;
    width: auto;
    flex-wrap: wrap;
    align-items: center;
}
.modalasociados input[type="checkbox"] {
    margin: 0 0 0 8px;
}
.listacampos2 h4 {
    font-size: 16px !important;
    font-weight: normal !important;
}
.listacampos2 {
    display: flex;
    flex-direction: column;
}
.listabotscampos {
    display: inline-block;
}
.modalasociados .listabotscampos button {
    padding: 6px 10px !important;
    font-size: 16px !important;
    margin: 0 10px 15px 0 !important;
    background: #767676 !important;
    color: #fff !important;
    -webkit-border-radius: 3px !important;
    border-radius: 3px !important;
}
.modalasociados button.anadir {
    padding: 6px 10px !important;
    font-size: 16px !important;
    margin: 0 10px 15px 0 !important;
    background: #767676 !important;
    color: #fff !important;
    -webkit-border-radius: 3px !important;
    border-radius: 3px !important;
}
.modalasociados .agrupacion button {
    margin-left: 0;
    margin-right: 0;
}
.modalasociados .ordenalista3 {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 15px;
}
.modalasociados .ordenalista3 div {
    margin: 0 10px 0 0;
    display: flex;
    align-items: center;
}
.modalasociados .ordenalista button {
    display: flex;
}
.modalasociados .ordenalista button img {
    width: 20px;
    height: 20px;
    margin: 0 0 0 5px;
}
.modalasociados input[type="checkbox"] {
    width: 17px;
    height: 17px;
}
/*****listados*****/
.listados .col-2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.listados select {
    padding: 3px;
    font-size: 14px;
    margin-left: 15px;
    background: none;
    border: 1px solid #000;
    -webkit-border-radius: 3px 3px 3px 3px;
    border-radius: 3px 3px 3px 3px;
}
.dashboard .listados button {
    background: #a30039 !important;
    padding: 4px 8px !important;
    font-size: 14px !important;
    color: #fff !important;
    -webkit-border-radius: 3px 3px 3px 3px !important;
    border-radius: 3px 3px 3px 3px !important;
    margin-right: 10px;
}
.dashboard .listados button.enable {
    pointer-events: initial;
    cursor: pointer;
    background: #a30039 !important;
}
.listados .col-2:first-child {
    max-width: min-content;
    margin-right: 15px;
    min-width: 200px;
}
.listados .col-2 {
    max-width: min-content;
}
.listados {
    justify-content: flex-start !important;
}
.listadopag .page-navigation {
    margin-bottom: 25px !important;
}
/*****cuotas******/
.cuotas .tablacuotas p {
    margin: 0 !important;
}
/*** socios ******/
.tablaresultados.socios tr td:nth-child(2) p {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-padding-end: 10px;
    margin: 0 !important;
}
.tablaresultados.socios tr td:nth-child(4) p {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-padding-end: 10px;
    margin: 0 !important;
}
.boton_input {
    position: absolute;
    top: 17px;
}
.boton_input img {
    width: 20px;
}
/**********/
.ml-3 {
    margin-left: 1rem;
}
#sidebaricon {
    height: 22px !important;
    min-height: 22px !important;
}
.selectidiomas img {
    height: 100%;
    width: 50%;
    align-self: center;
    border-radius: 0 50px 50px 0;
}
.selectidiomas h3 {
    margin: 0px;
    font-size: 1em;
    font-weight: normal;
    justify-content: space-between;
    align-items: center;
    display: flex;
    height: 100%;
}
.selectidiomas span {
    line-height: 1rem;
    padding-left: 8px;
    padding-right: 8px;
}
.selectidiomas {
    width: 80px;
    height: 25px;
    border: 1px solid var(--color-contrast);
    border-radius: 50px;
}
/* .dashboard .selectidiomas button {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;
    padding: 0 5px !important;
    overflow: hidden;
}
*/
.selectidiomas li {
    padding: 0 !important;
}
.selectidiomas:hover {
    cursor: pointer;
}
.selectidiomas input[type=checkbox] {
    border: none !important;
    outline: 0 !important;
}
.selectidiomas ul li {
    display: flex;
    flex-direction: row;
}
.selectidiomas #toggle {
    display: none;
}
.selectidiomas.cerrado #two {
    display: none;
}
.selectidiomas.loginidiomas #two ul {
    margin: 0;
    display: flex;
    flex-direction: column;
    opacity: 1;
    width: 100%;
    margin-top: -25px;
}
.selectidiomas {
    z-index: 1;
}
.selectidiomas li {
    height: 24px;
    border-radius: 50px;
    overflow: hidden;
}
.loginidiomas button {
    height: 100%;
    background: var(--color-background-contrast);
    border: none !important;
    outline: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    overflow: hidden;
}
.loginidiomas button:hover {
    background-color: var(--color-contrast);
}
.login-back {
    position: absolute;
    top: 2rem;
    left: 2rem;
    color: var(--color-text-secondary);
    font-size: 1.5rem;
    transition: all 0.3s;
    display: flex;
    align-items: center;
}
.login-back span {
    margin-top: 0.35rem;
    line-height: 1.5rem;
}
.login-back:hover {
    color: var(--color-text);
}
.login-back:hover * {
    fill: var(--color-text) !important;
}
.login-back svg {
    height: 2rem;
}
.login-back svg * {
    fill: var(--color-text-secondary) !important;
}
.unstyled-button {
    background-color: transparent;
    border: none;
    margin: 0 !important;
    padding: 0 !important;
}
/*arreglo botones 3/11/2023*/
.idiomas p {
    margin: 0 10px 0 0 !important;
    padding: 0;
    font-size: 12px;
}
.idiomas {
    margin: 45px 0 0 0 !important;
}
.contidiomas {
    display: flex;
    align-items: center;
    justify-content: center;
}
.tablaresultados td {
    vertical-align: top;
}
.navbar > .container-fluid .navbar-brand {
    margin-left: 0;
}
.tablaresultados.tablaasociados tr td:nth-child(3) p {
    -webkit-padding-end: 0px;
    text-wrap: wrap;
    display: inline-block;
    max-width: 80%;
}
.tablaresultados.tablaasociados tr td:nth-child(1) p {
    display: inline-block;
    text-wrap: wrap;
    margin: 0 !important;
    max-width: 80%;
}
.tablaresultados.tablaasociados tr td:nth-child(1) img {
    margin-top: 5px !important;
}
.fixposition {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}
.tablaresultados.tabladojos tr td:nth-child(3) p {
    -webkit-padding-end: 0px;
    text-wrap: wrap;
    display: inline-block;
    max-width: 80%;
}
.tablaresultados.tabladojos tr td:nth-child(1) p {
    display: inline-block;
    text-wrap: wrap;
    margin: 0 !important;
    max-width: 80%;
}
.tablaresultados.tabladojos tr td:nth-child(1) img {
    margin-top: 5px !important;
}
.detalles {
    display: none;
}
.listaoculta {
    display: none;
}
[type=search] {
    outline-offset: 0 !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    background-color: #a30139 !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-cancel:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
div:where(.swal2-icon).swal2-error {
    border-color: #a30139 !important;
    color: #a30139 !important;
}
div:where(.swal2-icon).swal2-error [class^=swal2-x-mark-line] {
    background-color: #a30139 !important;
}
div:where(.swal2-icon).swal2-question {
    border-color: #6e7881 !important;
    color: #6e7881 !important;
}
.page-link:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
    background: none !important;
}
p {
    font-size: 14px;
    margin: 5px 0 !important;
}
input {
    font-size: 14px !important;
}
/*29/11/2023*/
.imgpin {
    width: 35px;
    padding: 1rem;
    margin-top: -3px;
}
.contpin {
    float: right;
}
.modalcuotas .contresto .scroll {
    max-height: 300px;
}
.tablaresultados input[type="checkbox"] {
    margin: 4px 4px 4px 0;
    top: 2px;
    position: relative;
}
.dashboard .nav li:nth-child(5) {
    justify-content: space-between;
}
/* .aviso {
    width: 20px !important;
    height: 20px !important;
    background: #a8003b !important;
    border-radius: 20px 20px 20px 20px !important;
    color: #fff !important;
    display: flex !important;
    justify-content: center !important;
    font-size: 11px;
}
.aviso p {
    margin: 0 !important;
    padding: 0 !important;
    line-height: 18px;
}
*/
/*tabla*/
.modalcuotas .cerrarmodal {
    display: flex;
}
/* .cuerpo {
    display: flex;
    align-items: center;
    flex-direction: row;
}
*/
.modalcuotas .divtabla button {
    padding: 0 !important;
    color: #7d7d7d;
    font-weight: bold;
}
.modalcuotas .tablaejem {
    border-spacing: 0;
    width: 100%;
}
.modalcuotas .tablaejem thead th {
    padding: 0;
    top: 0;
    position: sticky;
    font-size: 14px;
    color: #1d5475;
}
.modalcuotas .tablaejem tbody tr td {
    padding: 5px;
}
.modalcuotas .tablaejem tr {
    color: #496f98;
}
.modalcuotas .tablaejem thead tr {
    position: relative;
    z-index: 999;
}
.modalcuotas .divtabla tbody {
    display: table-row-group;
}
/* .modalcuotas tbody tr img {
    width: 6px;
    margin-top: -3px;
    margin-right: 5px;
    margin-left: 4px;
}
*/
.modalcuotas .desplegado {
    border: 0;
}
.modalcuotas .tablaejem button:focus {
    border: 0 !important;
    outline: 0 !important;
}
/* .modalcuotas .tablaejem button:hover {
    -moz-transform: rotate(90deg) translateX(2px) translateY(2px);
    -webkit-transform: rotate(90deg) translateX(2px) translateY(2px);
    -o-transform: rotate(90deg) translateX(2px) translateY(2px);
    -ms-transform: rotate(90deg) translateX(2px) translateY(2px);
    transform: rotate(90deg) translateX(2px) translateY(2px);
}
*/
.modalcuotas .tablaejem button.desplegado {
    -moz-transform: rotate(90deg) translateX(2px) translateY(2px);
    -webkit-transform: rotate(90deg) translateX(2px) translateY(2px);
    -o-transform: rotate(90deg) translateX(2px) translateY(2px);
    -ms-transform: rotate(90deg) translateX(2px) translateY(2px);
    transform: rotate(90deg) translateX(2px) translateY(2px);
}
.modalcuotas .numeros {
    text-align: right;
}
.modalcuotas tr {
    padding: 0;
    align-items: center;
    display: flex;
   /* border: black 1px solid;
    */
}
/* .modalcuotas th {
    border: black 1px solid;
}
*/
.modalcuotas .conttablachecks .col-xs-2 {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
}
.modalcuotas .conttablachecks .col-xs-2 span {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
}
.modalcuotas tr td {
    font-size: 14px;
    padding: 0 !important;
}
.modalcuotas tr:first-child th {
    background: #fff;
}
.modalcuotas .tabladatos {
    width: 100%;
}
.tabladatos p {
    margin: 0;
    float: left;
}
.modalcuotas .divtabla::-webkit-scrollbar {
    width: 10px;
    background: #f1f1f1;
}
.modalcuotas .divtabla::-webkit-scrollbar:horizontal {
    height: 10px;
}
.modalcuotas .divtabla::-webkit-scrollbar-thumb {
    background: #00aedc !important;
}
.modalcuotas .divtabla {
    scrollbar-width: thin !important;
    overflow-y: auto;
    scrollbar-color: #00aedc #dfe0e5;
}
.modalcuotas .tabladatos tr:last-child td {
    bottom: -18px;
    position: sticky;
    background: #ccc;
}
.modalcuotas .divtabla td {
    border-bottom: 3px solid #fff;
}
/*21/11/2023*/
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
    color: #a30139 !important;
    color: #fff !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm:focus {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
.modal-main {
    min-height: 500px;
    align-items: center;
}
/* .precarga {
    height: 100%;
    z-index: 9999999;
    background: #fff;
}
*/
.custom-modal {
    min-height: 500px;
}
.container_modal {
    display: flex;
    align-items: center;
    min-height: 50%;
}
/* .dashboard .modal-main .col-xs-10 {
    padding: 10px 20px;
}
*/
.dashboard .modal-main .nuevazona.col-xs-12 {
    padding: 0 !important;
}
.dashboard .modal-main .col-xs-6 {
    padding: 0 !important;
}
.modal-main .dashboard .fotoperfil {
    margin: 0 !important;
}
.dashboard .filaperfil {
    min-height: 90px;
    display: flex;
    align-items: center;
    margin-bottom: 45px;
    padding: 15px 0 !important;
    align-items: flex-end;
}
.modal-main .nuevazona .btnswitch {
    font-size: 14px !important;
}
.modal-main .nuevazona .btnguardar {
    display: flex;
    font-size: 14px !important;
}
.modal-main .nuevazona .btnguardar img {
    margin-top: 0;
}
.modal-main .nuevazona .btnguardar:hover {
    background: #a3013b;
    color: #fff;
}
.modal-main .validacion img {
    width: 15px !important;
}
.modal-main .nuevazona .btnswitch:disabled {
    opacity: 0.5;
}
.modal-main .nuevazona .btnswitch:disabled:hover {
    opacity: 0.5;
}
.nuevazona .btnswitch {
    color: #a06868 !important;
}
/****inicio******/
.dashboard button.btnlista {
    background: #d9cacf !important;
    padding: 8px 12px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    color: #824c4c;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    font-size: 16px;
}
.dashboard button.btnlista.active {
    background: #a8003b !important;
    color: #fff !important;
}
.dashboard button.btnlista.inv {
    display: none;
}
.listaselects p {
    margin-right: 10px !important;
}
.btnlista:last-child {
    margin-right: 0;
}
.edit {
    width: 16px;
    margin-left: 4px;
}
.inicio a {
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    color: #a8003b;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-items: flex-start;
}
.inicio a:hover {
    opacity: 0.3;
}
.pagadas {
    color: #2eab3d;
    font-weight: bold;
}
.nopagadas {
    color: #a8003b;
    font-weight: bold;
}
.inicio h3 {
    font-weight: normal;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    line-height: 24px;
}
.fechas h3 {
    display: inline-block;
}
.inicio ul {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px !important;
    flex-wrap: wrap;
}
.inicio li {
    font-size: 16px;
    margin-right: 25px;
}
.inicio li:last-child {
    margin-right: 0;
}
.inicio select {
    font-size: 16px;
    width: 100%;
    padding: 3px;
}
.inicio p {
    font-size: 16px !important;
}
.inicio hr {
    margin-bottom: 25px;
}
.inicio {
    display: flex;
    flex-direction: column;
}
.inicio .fechas p {
    padding: 0;
    color: #fff;
    border-radius: 5px 5px 5px 5px;
    z-index: 9999;
    position: relative;
    min-height: 45px;
    min-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px !important;
    margin: 3px 0 0 0 !important;
}
.listaselects2 li {
    margin-right: 8px !important;
    min-height: 80px;
}
.listaselects2 li img {
    width: 15px;
    position: absolute;
   /* min-height: 70px;
    */
    top: 55px;
    z-index: 999999;
    margin-left: 4px;
}
.dashboard .inicio .col-xs-6 {
    padding: 0 !important;
}
.listaselects ul:first-child li {
    margin-right: 25px !important;
}
.inicio .listaselects img {
    width: 20px;
    margin-right: 5px;
}
.inicio .listaselects p {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px !important;
}
/*29/12/2022*/
.bloquesubir img {
    width: 12px !important;
    height: 13px !important;
    display: block;
    margin-left: 3px;
}
.dashboard .nuevazona .continput:last-child {
    margin-bottom: 15px;
}
.bloquesubir label {
    z-index: 2;
    background: #000;
    font-size: 10px;
    left: 49px;
    width: 105px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-left: -50px;
    border-radius: 5px 5px 5px 5px;
    color: #fff;
    cursor: pointer;
    transition: all 0.25s ease;
    position: absolute;
    opacity: 1;
    font-weight: normal;
}
.bloquesubir input {
    width: 104px !important;
    height: 27px;
    margin-left: 0;
    margin-top: 0;
    position: absolute;
    right: 136px;
    z-index: 0;
}
.bloquesubir {
    width: 240px;
}
.fotoperfil {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    margin-bottom: 15px;
}
.fotoperfil p {
    margin: 0 0 5px 0 !important;
    font-weight: bold;
    font-size: 11px;
}
.nombreasoc {
    margin-left: 10px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}
.fotoperfil button {
    display: flex;
    align-items: flex-end;
}
.fotoperfil .photo img {
    width: 55px !important;
    height: 55px !important;
    margin: -3px 0 0px -3px;
}
.inputfecha {
    padding: 0;
}
.contbutton {
    display: flex;
    justify-content: flex-start;
    gap: 5px;
}
.modalasocs .contbutton {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}
.modalasocs .continput {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.modalasocs .nuevazona h4 {
    display: flex;
    margin-top: 8px;
}
/* .modalasocs .container_modal {
    max-width: 370px;
}
*/
.container_modal {
    margin-bottom: 0 !important;
    padding: 20px 20px 20px 20px !important;
}
.navigation {
    display: flex;
    align-items: center;
}
.inline {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 15px;
}
.contphoto {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
    margin-top: 0;
    z-index: 1;
}
.cerrarsesion {
    margin-bottom: 5px;
    min-width: 100px;
    justify-content: flex-end;
}
.photo {
    -webkit-border-radius: 100px 100px 100px 100px;
    border-radius: 100px 100px 100px 100px;
    overflow: hidden;
    border: 0.5px solid #000;
    width: 50px;
    height: 50px;
}
.photo img {
    width: 55px !important;
    height: 55px !important;
    margin: -3px 0 0px -3px;
}
.photo_row {
    -webkit-border-radius: 100px 100px 100px 100px;
    border-radius: 100px 100px 100px 100px;
    overflow: hidden;
    border: 0.5px solid #000;
    width: 20px;
    height: 20px;
    margin-top: -20px;
    margin-left: 12px;
    position: absolute;
}
.photo_row img {
    width: 22px;
    height: 22px;
    position: absolute;
    top: -2px;
    left: -2px;
}
.pagactive {
    color: #a30139 !important;
    font-weight: bold;
}
.pagdes {
    cursor: auto;
    opacity: 0.5;
    pointer-events: none;
}
.antsig {
    color: #5c5c5c !important;
}
.clikable {
    cursor: pointer;
}
.no-clikable {
    cursor: auto;
    pointer-events: none;
}
.page-navigation {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    justify-content: flex-end;
    padding: 5px 0 0 0;
    align-items: center;
    font-size: 14px;
}
.page-navigation button {
    color: #a30139;
    margin-left: 5px;
}
.tabladatos td h6 {
    float: left;
    font-size: 10px;
    font-weight: bold;
    margin: 0 5px 0 0 !important;
}
.tabladatos td ul {
    float: left;
}
.tabladatos td button {
    float: left;
    margin-right: 5px;
    text-align: left;
}
.listaordena img {
    width: 10px !important;
    height: 10px !important;
    display: block;
}
.listaordena {
    display: inline-block;
    top: 2px !important;
    position: relative;
}
.modalcuotas .listaordena {
    display: inline-block;
    top: 0 !important;
    position: relative;
    left: 3px;
}
.activasino button {
    margin: 0 3px !important;
    color: #a30139;
}
.activasino {
    min-width: 150px;
    float: right;
    text-align: center;
    background-color: white;
    position: absolute;
}
.nuevazona .btnguardar img {
    margin-left: 2px;
    width: 18px;
    height: 18px;
    display: block;
    margin-top: -2px;
}
.nuevazona .btnguardar {
    display: flex;
    align-items: center;
    background: #a30139;
    color: #fff;
    padding: 5px 8px 4px 10px !important;
    font-size: 1.1em;
}
.nuevazona .btnswitch {
    display: flex;
    align-items: center;
    padding: 5px 8px 4px 10px !important;
    font-size: 1.1em;
}
.nuevazona .btnswitch:disabled {
    cursor: auto;
}
.nuevazona .btnswitch.Act {
    background: #a30139;
    color: #fff !important;
}
.nuevazona .btnswitch.Des {
    background: #d9cacf;
    color: #000;
}
.dashboard .nuevazona .continput {
   /* padding: 0 10px 0 0!important;
    */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    max-height: 50px;
    margin-bottom: 20px;
}
.dashboard .nuevazona .continput {
    padding: 0 5px 0 5px !important;
}
.dashboard .nuevazona .continput input {
    width: 100%;
    min-height: 30px;
}
.dashboard .nuevazona .continput select {
    padding: 3px 0;
    font-size: 14px;
    cursor: pointer;
    width: 100%;
    max-height: none;
}
.nuevazona p {
    margin: 0 0 5px 0 !important;
}
.nuevazona p.error {
    color: #a30139;
    font-weight: 600;
    min-height: 15px;
}
.nuevazona h4 {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
}
.nuevazona h4 img {
    width: 20px !important;
    height: 20px !important;
    display: block;
    margin-right: 5px;
}
.dashboard .nuevazona.col-xs-12 {
    padding: 10px 10px 20px 10px !important;
}
.tablaresultados .alumnDes {
    cursor: pointer;
}
.tablaresultados tr td:nth-child(6) img {
    width: 25px;
}
.tablaresultados .alumnAct {
    cursor: pointer;
    background-color: #ededed;
}
.nuevazona .btn img {
    width: 15px;
    height: 15px;
    display: block;
}
.mb-3 {
    margin-bottom: 30px;
}
.miga a {
    color: #a30139;
}
.miga {
    margin: 0 0 20px 0;
    z-index: 2;
}
.miga .breadcrumb {
    padding: 0;
    margin-bottom: 20px;
    list-style: none;
    background: none;
    font-size: 16px;
}
.dashboard button.btred {
    background: #a30139 !important;
    padding: 4px 8px !important;
    display: flex !important;
    color: #fff !important;
    -webkit-border-radius: 5px 5px 5px 5px;
    border-radius: 5px 5px 5px 5px;
    margin-right: 10px;
   /* margin-top:-8px;
    */
    justify-content: space-between;
    align-items: center;
}
.dashboard button.btred.Des {
    background: #d9cacf !important;
    color: #824c4c !important;
    pointer-events: none;
}
.dashboard button.btred a {
    text-decoration: none !important;
}
a {
    text-decoration: none !important;
}
.dashboard button.btred span {
    text-decoration: none !important;
}
.dashboard .tablaresultados a:hover {
    text-decoration: none !important;
}
.dashboard button.btred img {
    display: block;
    width: 12px;
    margin-left: 3px;
}
.cerrarsesion {
    display: flex;
    align-items: center;
}
.cerrarsesion span img {
    width: 17px !important;
    margin-top: -3px;
}
.titularh2 h2 {
    font-size: 16px !important;
    font-weight: bold;
    margin: 0 0 0 5px;
}
.titularh2 {
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-bottom: 5px;
}
.titularh2 img {
    width: 17px;
}
.cerrarsesion {
    float: right;
    display: flex;
    align-items: center;
}
/* BARRA DE PROGRESO */
.progress-container {
    width: 100%;
    background: #f5f5f5;
   /* Gris claro */
    border-radius: 5px;
    margin-top: 10px;
    overflow: hidden;
}
.progress-bar {
    height: 12px;
    background: #a31f34;
   /* Rojo oscuro */
    text-align: center;
    color: white;
    font-size: 12px;
    font-weight: bold;
    line-height: 12px;
    transition: width 0.3s ease-in-out;
    border-radius: 5px;
}
/* 06/06/2024 */
*, *::before, *::after {
    box-sizing: border-box;
}
/* Aplicar la tipografía a toda la web */
body {
    font-family: var(--font-family-main);
    font-size: 14px !important;
}
#root {
    height: 100dvh;
}
/* Login */
#wrapper {
    background-color: var(--color-background-primary);
    overflow-y: auto;
}
#wrapper .login {
    background-color: var(--color-background-contrast);
    box-shadow: var(--box-shadow);
    width: 500px;
    height: 500px;
    max-width: 90%;
    border-radius: var(--border-radius);
    margin: auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.login-button {
    background-color: var(--color-primary) !important;
    color: white;
    font-family: var(--font-family-main);
    font-size: inherit !important;
    font-weight: 700;
    border-radius: 50px;
    padding: 8px 32px;
    border: 1px solid var(--color-primary);
    transition-duration: 0.3s;
}
.login-button:hover {
    background-color: var(--color-alternative) !important;
}
.arriba {
    border-bottom: 1px solid var(--color-contrast);
}
.login ul {
    display: flex;
    justify-content: center;
    margin: 5px !important;
    padding: 0 !important;
}
.login button {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 100%;
}
.login li {
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.login li:has(img) {
    opacity: 0.5;
}
.login ul {
    transition-duration: 0.3s;
}
.logo {
    width: 110px;
}
.login form {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}
.login .abajo {
    position: relative;
}
.login .error {
    position: relative;
    margin: 0;
    width: 100%;
    height: 1rem;
}
.login .error .error1 {
    font-size: 1.2rem !important;
    font-weight: 400;
    position: absolute;
    text-align: center;
    width: 200%;
    transform: translateX(-25%);
}
.login input {
    border: 0;
    color: #545454 !important;
}
.login input:focus {
    outline: 0;
    border: 0;
}
.login .arriba img {
    width: 17px;
}
.login .abajo img {
    width: 18px;
}
.login .error {
    margin-top: 15px;
    color: #a30139;
    text-align: center;
    font-size: 1em;
}
.selectidiomas ul {
    opacity: 0.5;
    transition-duration: 0.3s;
    padding: 0 !important;
}
.selectidiomas ul li {
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* Scrollbar */
::-webkit-scrollbar {
    width: 6px;
}
::-webkit-scrollbar:horizontal {
    height: 6px;
}
::-webkit-scrollbar-track {
    background: var(--color-contrast);
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: var(--color-text-secondary);
    border-radius: 10px;
    transition-duration: 0.3s !important;
}
::-webkit-scrollbar-thumb:hover {
    background: var(--color-contrast-alternative);
}
/* Dashboard */
#wrapper {
    height: 100%;
    display: flex;
    align-items: center;
}
.dashboard {
    height: 100%;
    display: flex;
    width: 100%;
    min-height: calc(100% - 60px);
    margin: 0 auto;
    overflow-y: hidden;
}
.dashboard > *:not(#sidebar) {
    flex: 6 0 0;
}
#sidebar {
    flex: 1 0 0 !important;
    margin: 30px;
    margin-right: 0;
    overflow-y: auto;
}
.content {
    grid-column: 0.2857142857;
    height: 100%;
    overflow-y: auto;
}
.content-scroll {
    min-height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
}
nav {
    grid-column: 0.5;
}
.content-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    overflow: hidden;
    flex: 1 0 auto;
    min-height: 100%;
}
.flex-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
}
.flex-wrapper .input-calculated {
    width: auto !important;
    flex-grow: 1;
}
.space-between {
    display: flex;
    justify-content: space-between;
}
.flex-end {
    justify-content: flex-end;
    margin-left: auto;
}
.no-gap {
    gap: 0 !important;
}
.gap-small {
    gap: 10px !important;
}
.gap-medium {
    gap: 20px !important;
}
.gap-large {
    gap: 30px;
}
.no-wrap {
    flex-wrap: nowrap !important;
}
.no-padding {
    padding: 0 !important;
}
.flex-start {
    justify-content: flex-start;
    align-items: flex-start;
}
.underline {
    padding-bottom: 30px;
    border-bottom: 1px solid var(--color-contrast);
}
.overline {
    padding-top: 30px;
    border-top: 1px solid var(--color-contrast);
}
.backdrop {
    background-color: var(--color-background-contrast);
    border-radius: var(--border-radius);
    border: none !important;
    box-shadow: var(--box-shadow);
    padding: 30px !important;
    overflow: hidden;
}
nav {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    width: 100%;
    min-width: max-content;
}
nav ul.nav-menu {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    margin-bottom: 0 !important;
}
nav ul.nav-menu a {
    transition-duration: 0.3s;
    text-decoration: none;
    color: var(--color-text);
    border-radius: 50px;
    padding: 10px 15px 10px 10px;
    display: flex;
    font-weight: bold;
}
nav ul.nav-menu a svg {
    height: 22px;
    width: 22px;
}
nav ul.nav-menu a svg path {
    fill: var(--color-text);
}
nav ul.nav-menu a:hover {
    background-color: var(--color-contrast);
    cursor: pointer;
}
nav ul.nav-menu a:hover .notification {
    border-color: var(--color-contrast);
}
nav ul.nav-menu a.active {
    background-color: var(--color-primary);
}
nav ul.nav-menu a.active button {
    color: var(--color-background-contrast);
}
nav ul.nav-menu a.active svg path {
    fill: var(--color-background-contrast);
}
nav ul.nav-menu a.active .notification {
    background-color: var(--color-background-contrast);
    color: var(--color-primary);
}
nav ul.nav-menu a.active:hover {
    background-color: var(--color-alternative);
}
nav ul.nav-menu a.active:hover .notification {
    border-color: var(--color-alternative);
}
nav ul.nav-menu a button {
    display: flex;
    gap: 8px;
    align-content: center;
    border: none;
    background-color: transparent;
}
nav .nav-menu li {
    position: relative;
}
button:has( .notification) {
    align-items: center;
}
.notification {
    position: absolute;
    right: 10px;
    background: var(--color-text);
    border-radius: 30px;
    color: var(--color-background-contrast);
    font-size: 14px;
    line-height: 14px;
    font-weight: bold;
    padding: 0.5rem;
    align-self: center;
}
.notification-embed {
    background: var(--color-background-contrast);
    border-radius: 30px;
    color: var(--color-primary);
    font-size: 10px;
    line-height: 10px;
    font-weight: bold;
    padding: 0.5rem;
    align-self: center;
}
.cta:has(.notification-embed) {
    padding-right: 8px;
}
.notificar {
    background-color: var(--color-background-primary);
}
.notificar:not(.selected) .table-button a {
    background-color: var(--color-contrast);
}
.notificar:not(.selected) .table-button a:hover {
    background-color: var(--color-contrast-alternative) !important;
}
.estado-wrapper {
    display: flex !important;
    flex-direction: row;
    gap: 1rem;
}
.estado-wrapper div {
    width: fit-content !important;
}
.estado-wrapper div svg {
    height: 18px;
}
.estado-wrapper div:has(svg) {
    background-color: var(--color-alternative);
    border-radius: 50px;
    height: 21px;
    width: 21px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.estado-wrapper div:has(svg) svg * {
    fill: var(--color-background-contrast);
}
.estado-wrapper.aprobado div:has(svg) {
    background-color: var(--color-background-primary);
    transition-duration: 0.3s;
}
.estado-wrapper.aprobado div:has(svg):hover {
    background-color: var(--color-contrast);
}
.estado-wrapper.aprobado div:has(svg) svg * {
    fill: var(--color-text-secondary);
}
.table-inscripcion-mod .estado-container {
    width: fit-content;
    background-color: var(--color-primary);
    color: var(--color-background-contrast) !important;
    cursor: pointer;
    transition: all 0.3s;
}
.table-inscripcion-mod .estado-container:hover {
    background-color: var(--color-alternative);
}
.estado-container {
    padding: 0px 8px;
    border-radius: 50px;
    background-color: var(--color-background-primary);
    color: var(--color-text-secondary) !important;
    width: fit-content !important;
    font-weight: normal;
    width: fit-content;
    background-color: var(--color-primary);
    color: var(--color-background-contrast) !important;
    cursor: pointer;
    transition: all 0.3s;
}
.estado-container:hover {
    background-color: var(--color-alternative);
}
.estado-container.terminado {
    color: var(--color-background-primary) !important;
}
.estado-container.disabled {
    background-color: #ecd0da;
    color: #c290a2 !important;
    pointer-events: none;
    user-select: none;
}
.estado-container__count {
    border-radius: 50px;
    background-color: var(--color-harmonic);
    width: fit-content !important;
    font-weight: normal;
    padding: 8px 16px;
    color: var(--color-alternative);
}
.estado-container__count svg {
    height: 18px !important;
    margin-right: 10px;
    margin-left: -5px;
}
.estado-container__count svg * {
    fill: var(--color-alternative);
}
.aprobado .estado-container {
    background-color: var(--color-alternative);
    color: var(--color-background-contrast) !important;
}
.pago-asistencia-checkbox__container {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.pago-asistencia-checkbox__container input[type="checkbox"] {
    margin-top: 0 !important;
    position: relative;
    appearance: none;
    width: 20px !important;
    padding: 0 !important;
    height: 20px !important;
    border-radius: 5px !important;
    background-color: var(--color-background-contrast) !important;
    border: none !important;
    cursor: pointer;
    z-index: 0;
}
.pago-asistencia-checkbox__container input[type="checkbox"]:focus {
    outline: none;
}
.pago-asistencia-checkbox__container input[type="checkbox"].disabled {
    background-color: transparent !important;
    cursor: not-allowed;
}
.pago-asistencia-checkbox__container input[type="checkbox"]:checked {
    background-color: var(--color-primary-contrast) !important;
}
.pago-asistencia-checkbox__container input[type="checkbox"]:checked::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 13px;
    height: 4px;
    background-color: var(--color-primary);
    border-radius: 2px;
}
.pago-asistencia-checkbox__container input[type="checkbox"]:checked::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 13px;
    height: 4px;
    background-color: var(--color-primary);
    border-radius: 2px;
}
.active_user-avatar {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: max-content;
    align-items: center;
    margin-top: auto;
}
.active_user-avatar .avatar {
    height: 60px;
    border-radius: 100px;
}
.active_user-avatar button {
    color: var(--color-text);
    font-weight: 500;
    border: none;
    background-color: transparent;
    padding: 8px 16px;
    display: flex;
    gap: 8px;
    align-items: center;
    transition-duration: 0.3s;
}
.active_user-avatar button img {
    height: 20px;
}
.user-avatar .avatar {
    height: 40px;
    border-radius: 100px;
}
.user-avatar img {
    height: 0.75rem;
}
.space-between {
    display: flex;
    justify-content: space-between;
}
.cta {
   /* height: 100%;
    */
    display: flex;
    align-items: center;
    color: var(--color-background-contrast);
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary) !important;
    gap: 8px;
    border-radius: 30px;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 1.2rem;
    transition-duration: 0.3s;
    width: fit-content;
}
.cta span {
    justify-content: center;
    align-content: center;
}
.cta svg {
    width: 18px;
}
.cta svg path, .cta svg polygon {
    fill: var(--color-background-contrast);
}
.cta:hover {
    background-color: var(--color-alternative);
    border: 1px solid var(--color-alternative);
}
.cta.disabled {
    background-color: var(--color-background-primary);
    color: var(--color-contrast);
    border: 1px solid var(--color-background-primary) !important;
    pointer-events: none;
}
.cta.disabled path, .cta.disabled polygon {
    fill: var(--color-contrast);
}
.button {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 8px;
    height: 3.5rem;
    border-radius: 30px;
    padding: 8px 16px;
    border: 0;
    font-weight: 600;
    font-size: 1.2rem;
    transition-duration: 0.3s;
}
.button svg {
    height: 18px;
}
.button:hover {
    cursor: pointer;
}
.button.primary {
    font-weight: 600;
    border: 1px solid var(--color-primary);
    color: var(--color-background-contrast);
    background-color: var(--color-primary);
}
.button.primary path, .button.primary polygon {
    fill: var(--color-background-contrast);
}
.button.primary:hover {
    background-color: var(--color-alternative);
    border: 1px solid var(--color-alternative);
}
.button.contrast {
    font-weight: 600;
    border: 1px solid var(--color-primarycontrast);
    color: var(--color-primary);
    background-color: var(--color-primary-contrast);
}
.button.contrast path, .button.contrast polygon {
    fill: var(--color-contrast);
}
.button.contrast:hover {
    background-color: var(--color-background-primary);
    border: 1px solid var(--color-background-primary);
}
.button.secondary {
    font-weight: 500;
    border: 1px solid var(--color-text);
    color: var(--color-background-contrast);
    background-color: var(--color-text);
}
.button.secondary path, .button.secondary polygon {
    fill: var(--color-background-contrast);
}
.button.secondary:hover {
    background-color: var(--color-text-secondary);
    border: 1px solid var(--color-text-secondary);
}
.button.tertiary {
    font-weight: 500;
    border: 1px solid var(--color-text);
    color: var(--color-text);
    background-color: var(--color-background-contrast);
}
.button.tertiary svg * {
    fill: var(--color-text) !important;
}
.button.tertiary:hover {
    background-color: var(--color-background-primary);
}
.button.icon {
    border: none;
    padding: 8px !important;
}
.button.icon.download-icon {
    transform: rotate(10deg);
}
.button.icon path, .button.icon polygon {
    fill: var(--color-text);
}
.button.icon.disabled {
    background-color: transparent;
    border: none;
}
.button.active {
    border: 1px solid var(--color-primary);
    color: var(--color-background-contrast);
    background-color: var(--color-primary);
}
.button.active path, .button.active polygon {
    fill: var(--color-background-contrast);
}
.button.active:hover {
    background-color: var(--color-alternative);
}
.button.disabled {
    background-color: var(--color-background-primary);
    color: var(--color-contrast);
    border: 1px solid var(--color-background-primary);
    pointer-events: none;
}
.button.disabled path, .button.disabled polygon {
    fill: var(--color-contrast);
    opacity: 0.15;
}
.unstyled-button {
    display: flex;
    align-content: center;
    justify-content: center;
    border: none;
    background-color: transparent;
}
.unstyled-button path {
    fill: var(--color-primary);
    transition-duration: 0.3s;
}
.unstyled-button:hover path {
    fill: var(--color-alternative);
}
.info-text {
    font-style: italic;
}
.dashboard .logo {
    width: 100%;
    padding: 10% 30% 15% 30%;
    align-self: center;
    justify-self: center;
    margin: 0;
}
header {
    display: flex;
    align-items: center;
    gap: 8px;
    height: calc(2rem + 60px);
    min-height: fit-content;
}
header .section-icon {
    height: 3.2rem;
    width: 3.2rem;
}
header .section-icon path {
    fill: var(--color-text);
}
header h1 {
    min-height: 2rem;
    margin: 0;
    color: var(--color-text);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 1;
}
.main-section {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.main-section .backdrop:last-child {
    flex-grow: 1;
}
.n-results {
    display: flex;
    gap: 1rem;
    align-items: center;
    font-weight: 400;
    height: 3rem;
    color: var(--color-contrast-secondary);
}
.estado-filtro {
    padding: 0px 8px;
    border-radius: 50px;
    background-color: var(--color-background-primary);
    color: var(--color-text-secondary) !important;
    width: fit-content !important;
    font-weight: normal;
    display: flex;
    align-items: center;
    gap: 0.25rem;
}
.view-input {
    font-weight: 900;
    color: var(--color-primary);
    width: fit-content;
    text-align: left;
    background-color: var(--color-harmonic);
    padding: 4px;
    height: 100%;
    border: none;
    border-radius: var(--border-radius);
}
.filtering {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 40px;
}
.indicador-verde {
    width: 12px;
    height: 12px;
    border-radius: 50px;
    background-color: #49d60d;
}
.indicador-naranja {
    width: 12px;
    height: 12px;
    border-radius: 50px;
    background-color: #f9f631;
}
.page-navigation {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
}
.pagination {
    padding: 0;
    margin: 0;
    display: flex;
    align-content: center;
    gap: 8px;
}
.pagination * {
    font-size: 14px !important;
}
.pagination li button {
    padding: 0;
    background-color: transparent;
    margin: 0;
    border: none;
}
.scrollable-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding-bottom: 30px;
    overflow-y: auto;
    padding-left: 15px;
    padding-right: 15px;
}
.filter-conditions .search {
    width: 200px;
}
.filter-conditions .field-to-filter {
    font-weight: 600;
}
.filter-conditions select {
    height: 3.5rem;
    border-radius: 50px;
    padding: 8px 16px;
}
.filter-conditions select::placeholder {
    color: red;
}
.filter-group-wrapper {
    background-color: var(--color-background-primary);
    border-radius: calc(2*var(--border-radius));
    padding: 12px;
    border: 1px solid var(--color-background-primary);
    transition-duration: 0.3s;
}
.filter-group-wrapper .filter-group-header {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 600;
    padding-bottom: 12px;
}
.filter-group-wrapper .filter-group-content {
    display: flex;
    gap: 8px;
    align-items: center;
}
.filter-group-wrapper .filter-group-content .filter-single {
    background: var(--color-contrast);
    height: 3.5rem;
    padding: 12px;
    padding-right: 0;
    border-radius: 50px;
    display: flex !important;
    align-items: center !important;
}
.filter-group-wrapper .filter-group-content .filter-single span {
    padding: 12px 12px;
    font-weight: 600;
}
.filter-group-wrapper .filter-group-content .filter-single span:nth-child(2) {
    background: var(--color-text);
    border-radius: 30px;
    color: var(--color-background-contrast);
    padding: 8px 12px;
}
.filter-group-wrapper h4 {
    font-size: 1.5rem !important;
    transition-duration: 0.3s;
}
.filter-group-wrapper h4:hover {
    color: var(--color-text-secondary);
    cursor: pointer;
}
.filter-group-wrapper:hover {
    cursor: pointer;
    background-color: var(--color-contrast);
}
.filter-group-wrapper.active {
    border: 1px solid var(--color-text);
   /* & .active-checker {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(20px, 20px);
        width: 15px;
        height: 15px;
        background-color: var(--color-primary);
        border-radius: 50px;
   }
    */
}
.filter-group-wrapper.active h4 {
    color: var(--color-primary);
    transition-duration: 0.3s;
}
.filter-group-wrapper.active h4:hover {
    color: var(--color-alternative);
}
.filter-group-wrapper.active svg * {
    fill: var(--color-primary);
}
.filter-group-wrapper.active svg.disabled * {
    fill: var(--color-contrast-alternative);
}
.img-avatar-wrapper, .img-curso-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}
.img-avatar-wrapper .img-avatar-container, .img-curso-wrapper .img-avatar-container, .img-avatar-wrapper .img-curso-container, .img-curso-wrapper .img-curso-container {
    max-height: 400px;
    width: auto;
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    border-radius: 25px;
    border: 2px dashed var(--color-text);
    overflow: hidden;
}
.img-avatar-wrapper .img-avatar-container > div > div, .img-curso-wrapper .img-avatar-container > div > div, .img-avatar-wrapper .img-curso-container > div > div, .img-curso-wrapper .img-curso-container > div > div {
    border: none !important;
}
.img-avatar-wrapper svg:not(button svg), .img-curso-wrapper svg:not(button svg) {
    background-color: var(--color-primary);
    border-radius: 50px;
    height: 30px;
    width: 30px;
}
.img-avatar-wrapper button, .img-curso-wrapper button {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 30px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;
    padding: 4px 16px;
    border: 2px solid white !important;
}
.img-avatar-wrapper .avatar-cerrar, .img-curso-wrapper .avatar-cerrar {
    left: 10px !important;
    right: auto !important;
    width: 30px !important;
    padding: 1px !important;
}
.upload-curso-img__select {
    height: 400px;
    width: auto;
}
.ReactCrop__crop-mask {
    display: none;
}
.flex-start {
    align-items: flex-start !important;
}
.crear-curso p {
    margin-top: 0 !important;
}
.user-avatar, .upload-curso-img__wrapper {
    position: relative;
}
.user-avatar .newimg-icon, .upload-curso-img__wrapper .newimg-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 35px;
    height: 35px;
    background-color: var(--color-background-contrast);
    border: 2px solid var(--color-primary);
    padding: 5px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition-duration: 0.3s;
}
.user-avatar .newimg-icon *, .upload-curso-img__wrapper .newimg-icon * {
    fill: var(--color-primary) !important;
}
.user-avatar .newimg-icon:hover, .upload-curso-img__wrapper .newimg-icon:hover {
    background-color: var(--color-background-primary);
}
.modal_close-icon.avatar {
    width: 30px;
    height: 30px;
    border: none;
    background-color: var(--color-background-primary);
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
}
.modal_close-icon.avatar * {
    fill: var(--color-text);
}
.avCerrado, .cursoCerrado {
    display: none;
}
.img-avatar {
    background-color: white;
}
.img-avatar-container__bg, .img-curso-container__bg {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
}
.user-avatar__name {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
}
.nexus-field, .nexus-group {
    width: 3rem;
    display: flex;
    justify-content: center;
    color: var(--color-primary);
    font-weight: 600;
    padding: 8px;
}
.nexus-field:hover, .nexus-group:hover {
    color: var(--color-alternative);
    cursor: pointer;
    user-select: none;
}
.nexus-group {
    width: 100%;
}
.search {
    max-width: 300px;
}
.search form {
    display: flex;
    position: relative;
}
.search input {
    height: 3.5rem;
    color: var(--color-text);
    width: 100%;
    background-color: var(--color-background-primary);
    border: 1px solid var(--color-background-primary);
    border-radius: 50px;
    padding: 2px 16px;
    font-weight: 500;
}
.search input::placeholder {
    color: var(--color-contrast-alternative);
}
.search svg {
    height: 100%;
    position: absolute;
    right: 5px;
    transform-origin: 50% 50%;
    transform: scale(0.8);
    cursor: pointer;
}
.search svg path {
    fill: var(--color-contrast-alternative);
}
input::placeholder, textarea::placeholder, select::placeholder {
    color: var(--color-contrast-alternative);
}
.table-container {
    display: grid;
    grid-template-columns: 1fr;
    overflow-y: auto;
    overflow-x: auto;
}
.table-headers, .table-results {
   /* min-width: 0;
    */
    width: 100%;
}
.table-inscripcion-mod {
    overflow-x: visible;
    overflow-y: visible;
    height: fit-content;
}
.table-headers {
   /* position: sticky;
    */
   /* top: 0;
    */
    background-color: var(--color-background-primary);
    z-index: 1;
}
.table-container {
    overflow-x: auto;
}
.table-headers tr, .table-headers .table-headers--tr, .table-results tr, .table-results .table-results--tr {
    display: flex;
    width: 100%;
    align-items: center;
    min-height: 45px;
}
.table-headers tr.selected, .table-headers .table-headers--tr.selected, .table-results tr.selected, .table-results .table-results--tr.selected {
    box-shadow: inset 8px 0 0 0 var(--color-primary);
    background-color: var(--color-background-primary);
}
.table-headers th, .table-results td {
    flex: 1 0 150px;
    min-width: 150px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding: 0 10px;
}
.table-headers th *, .table-results td * {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.table-headers .table-headers--th, .table-results .table-results--td {
    flex: 1 0 0;
    min-width: 150px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    padding: 0 10px;
}
.table-headers tr, .table-headers .table-headers--tr {
    border-bottom: 1px solid var(--color-contrast);
    border-top: 1px solid var(--color-contrast);
    padding: 15px 15px;
}
.table-headers tr button, .table-headers .table-headers--tr button {
    text-align: left;
    border: none;
    background: transparent;
    padding: 0;
    flex: 1 0 0;
}
.table-headers tr th, .table-headers .table-headers--tr th, .table-headers tr .table-headers--th, .table-headers .table-headers--tr .table-headers--th {
    color: var(--color-contrast-alternative);
    font-weight: 600;
    padding: 0 10px;
}
.table-headers tr th svg, .table-headers .table-headers--tr th svg, .table-headers tr .table-headers--th svg, .table-headers .table-headers--tr .table-headers--th svg {
    width: 24px;
    height: 24px;
    padding: 0;
}
.table-headers tr th svg *, .table-headers .table-headers--tr th svg *, .table-headers tr .table-headers--th svg *, .table-headers .table-headers--tr .table-headers--th svg * {
    fill: var(--color-contrast-alternative);
}
.table-headers tr th ul, .table-headers .table-headers--tr th ul, .table-headers tr .table-headers--th ul, .table-headers .table-headers--tr .table-headers--th ul {
    padding-left: 10px;
    height: 1.5rem;
    margin: 0;
    flex: 0 1 min-content;
}
.table-results tr, .table-results .table-results--tr {
    padding: 5px 15px;
    transition-duration: 0.3s;
}
.table-results tr:hover, .table-results .table-results--tr:hover {
    background-color: var(--color-background-primary);
}
.table-results tr:hover .table-button a, .table-results .table-results--tr:hover .table-button a {
    background-color: var(--color-contrast);
}
.table-results a {
    color: var(--color-text);
    border-radius: 50px;
    width: 100% !important;
    padding: 0 8px;
}
.table-results a:hover {
    background-color: var(--color-contrast);
}
.table-results td, .table-results .table-results--td {
    display: block;
    height: 100%;
    color: var(--color-text);
    font-weight: 600;
}
.table-results td .fixposition, .table-results .table-results--td .fixposition {
    width: 100%;
}
.table-results svg {
    width: auto;
    height: 22px;
    padding: 0;
}
.validation-group {
    display: flex !important;
    gap: 1rem;
}
.validation-group svg:last-of-type {
    margin-left: -0.25rem;
}
.paid-fees, .grados-overview, .cursos-overview {
    height: 100%;
}
.paid-fees ul, .grados-overview ul, .cursos-overview ul {
    flex-wrap: wrap;
}
.paid-fees button, .grados-overview button, .cursos-overview button {
    margin-left: auto;
    margin-top: auto;
    padding: 4px 12px;
    justify-self: flex-end;
}
.cinturon-card {
    background-color: var(--color-background-primary);
    border-radius: var(--border-radius);
    padding: 4px 16px;
}
.table-grados-mod tbody tr:hover {
    background-color: var(--color-background-contrast) !important;
}
.table-grados-mod input[type="checkbox"] {
    position: relative;
    appearance: none;
    width: 20px !important;
    padding: 0 !important;
    height: 20px !important;
    border-radius: 5px !important;
    background-color: var(--color-background-primary) !important;
    border: none !important;
    cursor: pointer;
    z-index: 0;
}
.table-grados-mod input[type="checkbox"]:checked {
    background-color: var(--color-primary-contrast) !important;
}
.table-grados-mod input[type="checkbox"]:checked::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 13px;
    height: 4px;
    background-color: var(--color-primary);
    border-radius: 2px;
}
.table-grados-mod input[type="checkbox"]:checked::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 13px;
    height: 4px;
    background-color: var(--color-primary);
    border-radius: 2px;
}
.table-grados-mod input[type="checkbox"]:focus {
    outline: none;
}
.table-grados-mod input[type="date"] {
    background-color: var(--color-background-primary) !important;
}
.capitalize {
    text-transform: uppercase;
}
.cinturon-td {
    display: flex !important;
    flex-direction: row !important;
    gap: 8px;
}
.cinturon-td > * {
    width: auto !important;
}
.nuevo-grado--card {
    background-color: var(--color-background-primary);
    border-radius: var(--border-radius);
    padding: 1rem;
}
.grado-color {
    height: 1.2rem !important;
    width: 1.2rem !important;
    border-radius: 50px;
}
.grado-color.azul {
    background-color: #2e60ab;
}
.grado-color.marron {
    background-color: #8b4513;
}
.borde-grado--azul {
    border: 2px solid #2e60ab;
    padding: 2px;
}
.proximamente {
    font-style: italic;
    font-weight: 600;
    color: var(--color-text-secondary);
}
.flex-row-item {
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    flex-wrap: wrap;
}
.flex-row-item > .equal-width {
    flex-grow: 1;
    flex-basis: 0;
}
.flex-row-item.filtering {
    gap: 0.5rem;
}
.full-width {
    width: 100%;
}
.full-width-grow {
    flex-grow: 1;
}
.flex-column {
    display: flex;
    flex-direction: column !important;
    gap: 30px;
}
.inline-flex {
    display: inline-flex;
    gap: 8px;
}
.grid {
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    gap: 30px;
   /* flex: 1 0 auto;
    */
}
.grid.card {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.full-height {
    height: 100%;
    align-items: stretch;
}
.flex-full-width {
    flex: 1 0 0;
}
.tiny-column {
    flex: 0 0 25px !important;
    padding: 0 !important;
    margin: 0 !important;
    min-width: 25px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
}
.tiny-column input {
    width: 20px !important;
    margin: 0 !important;
}
.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.short-column {
    flex: 0 0 100px !important;
}
.ver-item {
    background-color: var(--color-background-primary);
    border: none;
    border-radius: 50px;
    display: flex;
    align-items: center;
}
.ver-item svg {
    width: 24px !important;
    height: 24px !important;
    padding: 0;
}
.ver-item svg * {
    fill: var(--color-contrast-alternative) !important;
}
.avatar-row {
    display: flex !important;
    gap: 8px;
}
.avatar-row img {
    width: 24px;
    border-radius: 100px;
    aspect-ratio: 1;
}
.table-button {
    display: flex !important;
    flex-direction: row;
}
.table-button.inscripcion a {
    background-color: var(--color-alternative);
    transition: background-color 0.3s;
    width: fit-content !important;
    padding: 4px 12px;
}
.table-button.inscripcion a:hover {
    background-color: var(--color-primary);
}
.table-button.inscripcion a span {
    font-weight: 600;
    text-align: center;
    color: var(--color-background-contrast);
}
.inscripcion:not(.cerrado) div {
    cursor: pointer;
    background-color: var(--color-alternative);
    transition-duration: 0.3s;
    color: var(--color-background-contrast) !important;
}
.inscripcion:not(.cerrado) div:hover {
    background-color: var(--color-primary);
}
.en-curso {
    background-color: #2e60ab !important;
}
.terminado {
    background-color: var(--color-contrast-alternative) !important;
}
.table-button a {
    width: 100%;
    border-radius: 30px;
    padding: 5px 16px 4px 8px;
    background-color: var(--color-background-primary);
}
.table-button a button {
    display: flex;
    justify-content: space-between;
    color: var(--color-contrast-alternative);
    width: 100%;
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
}
.table-button a button span {
    font-weight: 400;
    line-height: 2.3rem;
}
.table-button a button svg {
    width: 24px;
}
.table-button a button svg path {
    fill: var(--color-contrast-alternative);
}
.table-button.black a button {
    color: var(--color-text);
    gap: 0.5rem;
}
.table-button.black a button span {
    text-align: left;
    font-weight: 600;
}
.table-button.black a button svg path {
    fill: var(--color-text);
}
.hide-text {
    color: transparent !important;
}
.table-actions {
    min-width: 100px !important;
    flex: 0 0 100px !important;
    height: 100% !important;
}
.table-actions ul {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
}
.table-actions ul li {
    display: flex;
    justify-content: center;
    width: fit-content !important;
    border-radius: 5px;
    transition-duration: 0.3s;
}
.table-actions ul li svg {
    width: 22px;
    height: auto;
}
.table-actions ul li svg path {
    fill: var(--color-primary);
    transition-duration: 0.3s;
}
.table-actions ul li:hover {
    cursor: pointer !important;
    background-color: var(--color-contrast);
}
.table-actions ul li:hover path {
    fill: var(--color-alternative);
}
.curso-titulo {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: unset !important;
    text-overflow: ellipsis;
   /* Opcional, en combinación para navegadores más antiguos */
    height: 20px !important;
   /* Mantén si es necesario */
    min-width: 0 !important;
   /* Sigue siendo útil para Flexbox */
}
.short-column {
    flex: 0 0 50px !important;
    min-width: 50px !important;
}
.medium-column {
    flex: 0 0 80px !important;
    min-width: 80px !important;
}
.wide-column {
    flex: 0 0 110px !important;
    min-width: 110px !important;
}
.large-s-column {
    flex: 0 0 130px !important;
    min-width: 130px !important;
}
.large-column {
    flex: 0 0 150px !important;
    min-width: 150px !important;
}
.grow-column {
    flex-grow: 1 !important;
}
.shrink-column {
    flex-shrink: 1 !important;
}
.double-grow-column {
    flex-grow: 2 !important;
}
.iconos-usuario-container {
    display: flex;
    gap: 4px;
    align-items: center;
    justify-content: flex-start;
}
.iconos-usuario-container svg {
    width: 16px;
    height: 16px;
    padding: 0;
}
.iconos-usuario-container svg * {
    fill: var(--color-primary);
}
.table-headers.listado tr, .table-results.listado tr {
    display: flex;
}
/* Modal Template */
.filter-conditions input {
    width: fit-content !important;
}
.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100dvh;
    max-height: 100dvh;
    width: 100vw;
    max-width: 100vw;
    backdrop-filter: blur(2px);
    background-color: rgba(19, 19, 19, 0.5);
    z-index: 100;
   /* & .underline {
        padding-bottom: 15px !important;
   }
    & .overline {
        padding-top: 15px !important;
   }
    */
}
.modal-wrapper p {
    font-weight: 500;
}
.modal-wrapper input, .modal-wrapper select {
    height: 3.5rem;
    color: var(--color-text);
    background-color: var(--color-background-primary);
    border: 1px solid var(--color-background-primary);
    border-radius: 50px;
    padding: 2px 16px;
    font-weight: 500;
    width: 100%;
}
.modal-wrapper input::placeholder, .modal-wrapper select::placeholder {
    color: var(--color-contrast-alternative);
}
.modal-wrapper textarea {
    color: var(--color-text);
    width: 100%;
    background-color: var(--color-background-primary);
    border: 1px solid var(--color-background-primary);
    border-radius: var(--border-radius);
    padding: 2rem;
    font-weight: 500;
}
.modal-wrapper .error {
    position: absolute;
    margin: 0;
}
.modal-wrapper > .content-wrapper {
    min-height: unset !important;
}
.modal-container {
    gap: 0 !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 100%;
    flex-grow: 0 !important;
    position: relative;
    overflow-y: auto !important;
}
.modal-container.modal-plantillas {
    width: 100%;
}
.modal-container .section-icon {
    height: 3rem;
}
.modal-container .section-icon path {
    fill: var(--color-text);
}
.modal-container .location .section-icon {
    height: 2rem;
}
.modal-container h3 {
    margin: 0;
    font-size: 2.5rem !important;
    color: var(--color-text) !important;
    font-style: normal !important;
}
.modal-container h4 {
    margin: 0;
    font-size: 2rem;
    color: var(--color-text);
}
.modal-container .available-fields, .modal-container .available-aggs {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.modal-container .available-fields input[type="checkbox"], .modal-container .available-aggs input[type="checkbox"] {
    display: none;
}
.modal-container .available-fields input[type="checkbox"]:checked + label, .modal-container .available-aggs input[type="checkbox"]:checked + label {
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    color: var(--color-background-contrast);
}
.modal-container .available-fields label, .modal-container .available-aggs label {
    margin: 0;
}
.modal-container .selected-fields, .modal-container .selected-aggs {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.modal-container .selected-fields .remove-field path, .modal-container .selected-aggs .remove-field path, .modal-container .selected-fields .remove-field polygon, .modal-container .selected-aggs .remove-field polygon {
    fill: text;
    transition-duration: 0.3s;
}
.modal-container .selected-fields:hover + path, .modal-container .selected-aggs:hover + path, .modal-container .selected-fields :hover + polygon, .modal-container .selected-aggs :hover + polygon {
    fill: var(--color-contrast-alternative);
}
.action-buttons {
    display: flex !important;
    gap: 10px;
}
.action-button {
    width: fit-content !important;
}
.form-container, .inscribir-asociado-container {
   /* display: flex;
    flex-direction: column;
    */
    gap: 10px;
    overflow-y: auto;
}
.form-container > *:not(:last-of-type), .inscribir-asociado-container > *:not(:last-of-type) {
    margin-bottom: 30px;
}
.inscribir-asociado-container--row {
    display: flex;
    flex-direction: row;
    gap: 30px;
}
.inscribir-asociado__img {
    min-width: 212px;
    max-width: 212px;
    border-radius: var(--border-radius);
    overflow: hidden;
    max-height: 300px;
}
.inscribir-asociado__img img {
    height: 100%;
}
.curso-img {
    cursor: zoom-in !important;
}
.modal-curso-img__wrapper {
    max-height: 100%;
    max-width: 100%;
    height: fit-content;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden !important;
}
main.modal-wrapper:has(.modal-curso-img__img) {
    align-items: center;
    cursor: zoom-out;
}
.modal-curso-img__img {
    position: relative;
    padding: 0px !important;
    display: inline-block;
    max-height: 100%;
    max-width: 100%;
    height: fit-content;
    width: fit-content;
}
.modal-curso-img__img .modal_close-icon {
    padding: 1rem;
    margin: 1rem;
    width: 40px;
    background-color: white;
    border-radius: 100px;
}
.modal-curso-img__img .modal-curso-img__img--item {
    padding: 0 !important;
    display: block;
    max-height: 100%;
    max-width: 100%;
    height: auto;
    width: auto;
}
.grow {
    flex-grow: 1;
}
.inscribir-asociado-container .location, .inscribir-asociado-container .numacae {
    padding: 0px 8px 0px 0px;
    border-radius: 50px;
    background-color: var(--color-background-primary);
    color: var(--color-text-secondary) !important;
    width: fit-content !important;
    font-weight: normal !important;
}
.inscribir-asociado-container .location svg *, .inscribir-asociado-container .numacae svg * {
    fill: var(--color-text-secondary);
}
.inscribir-asociado-container .numacae {
    background-color: var(--color-contrast);
    padding: 0.15rem 1rem;
    color: var(--color-contrast-alternative) !important;
}
.select-sesiones svg.false * {
    fill: var(--color-contrast-alternative) !important;
}
.select-sesiones svg.true * {
    fill: var(--color-primary) !important;
}
.notification-text {
    font-weight: 600;
    color: var(--color-primary);
    font-size: 1.2rem;
    font-weight: bold !important;
    font-style: italic;
    margin: 0 !important;
    padding: 5px;
}
h2.numacae {
    background-color: var(--color-background-primary);
    padding: 0.15rem 1rem;
    border-radius: var(--border-radius);
    font-weight: 500;
    color: var(--color-contrast-alternative) !important;
}
#inicio .numacae {
    padding: 8px 16px !important;
    border-radius: 50px;
}
.toggle-perms__container {
    display: flex;
    flex-direction: row !important;
    gap: 10px;
}
.toggle-perms__container li {
    margin-bottom: 0 !important;
}
.toggle-perms__container li.disabled label {
    background-color: var(--color-background-primary);
    color: var(--color-contrast);
    border-color: var(--color-background-primary);
    pointer-events: none;
}
.toggle-perms__container li.disabled label svg * {
    fill: var(--color-contrast) !important;
}
.toggle-perms {
    display: none;
}
.toggle-perms ~ label {
    cursor: pointer;
    padding: 4px 12px 4px 8px !important;
    margin: 0 !important;
    height: auto !important;
}
.toggle-perms ~ label svg {
    height: 2rem !important;
}
.toggle-perms ~ label svg * {
    fill: white !important;
}
.inscribir-asociado-container {
    min-height: fit-content;
}
.flex-row-item:has(.inscribir-asociado-container) {
    padding-top: 30px;
    padding-bottom: 30px;
}
.modal-content-scroll {
    padding: 0 !important;
}
.date {
    margin: 0 !important;
}
.inscribir-asociado-container--row .date {
    gap: 5px;
    font-style: italic;
    font-size: 1.2rem;
}
.modal-content-outer-wrapper {
    padding: 30px;
    padding-bottom: 0;
}
.modal-content-outer-wrapper:last-child {
    padding-bottom: 30px;
}
.inscribir-asociado-container span {
    font-size: 1.5rem;
}
.observaciones-bg {
    background-color: var(--color-background-primary);
    padding: 1rem;
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    flex: 1;
}
.observaciones {
    width: 100%;
    color: var(--color-text);
    margin: 0 !important;
    white-space: pre-wrap;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 10;
    -webkit-line-clamp: 10;
    -webkit-box-orient: vertical;
}
.observaciones.show {
    line-clamp: unset;
    -webkit-line-clamp: unset;
}
.upload-curso-img__wrapper {
    display: flex;
    flex-direction: row;
    gap: 30px;
    height: 200px;
}
.upload-curso-img__wrapper img, .upload-curso-img__wrapper canvas {
    width: 100% !important;
    height: 100% !important;
    border-radius: var(--border-radius);
}
.upload-curso-img__img {
    height: 200px;
    max-width: 141px;
    min-width: 141px;
}
.upload-curso-img__placeholder, .upload-curso-img__preview {
    display: none;
}
.upload-curso-img__default {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 200px;
    min-width: 141px;
    background-color: var(--color-background-primary);
    border-radius: var(--border-radius);
    font-weight: 600;
    color: var(--color-text-secondary);
}
.left-radius {
    border-radius: 100px 0 0 100px;
}
.right-radius {
    border-radius: 0 100px 100px 0;
}
.no-radius {
    border-radius: 0 !important;
}
.upload-curso-img {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px !important;
    width: 200px;
    border-radius: var(--border-radius) !important;
    background-color: var(--color-background-contrast) !important;
}
.upload-curso-img .upload-curso-img__input {
    display: none;
    width: fit-content !important;
    height: fit-content !important;
    background: transparent !important;
    padding: 0;
    border-radius: 0;
    border: none;
    cursor: pointer;
}
.upload-curso-img .upload-curso-img__input::file-selector-button {
    background-color: var(--color-primary);
    color: var(--color-background-contrast);
    font-weight: bold;
    border: none;
    border-radius: var(--border-radius);
    padding: 8px 16px;
}
a.cell-td-button {
    width: fit-content !important;
    padding: 0.5rem 1rem !important;
    display: flex;
    align-items: center;
    min-width: 42px;
}
.selected .cell-td-button {
    background-color: var(--color-contrast-alternative) !important;
}
.selected .cell-td-button:hover {
    background-color: var(--color-text-secondary) !important;
}
.cell-td-button--label {
    width: 100% !important;
}
.cell-td-button--label-icon {
    transform: rotate(-45deg);
}
.cell-td-button--trigger {
    display: flex;
}
.table-cuotas .estado-container {
    width: fit-content;
    background-color: var(--color-primary);
    color: var(--color-background-contrast) !important;
    cursor: pointer;
    transition: all 0.3s;
}
.table-cuotas .estado-container:hover {
    background-color: var(--color-alternative);
}
.upload-curso-img__label {
    font-size: 1.25em;
    font-weight: 700;
    color: black;
    display: inline-block;
    font-family: sans-serif;
    cursor: pointer;
    line-height: 200px;
}
.ver-mas__button {
    margin-top: auto;
    color: var(--color-primary);
    font-weight: 600;
    border: none !important;
    padding: 0 !important;
    justify-self: right;
}
.inscribir-asociado-container.select-sesiones {
    background-color: var(--color-background-primary);
    border-radius: var(--border-radius);
    padding: 15px;
    gap: 20px;
    margin-top: 30px;
}
.inscribir-asociado-container.select-sesiones label {
    margin: 0 !important;
    height: 30px;
    cursor: pointer;
}
.inscribir-asociado-container.select-sesiones > *:has(.disabled) * {
    opacity: 0.75;
}
.inscribir-asociado-container.select-sesiones .disabled {
    border: none !important;
    cursor: not-allowed !important;
    user-select: none !important;
}
.inscribir-asociado-container.select-sesiones.skeleton * {
    opacity: 0.75;
}
.inscribir-asociado-container.select-sesiones .sesion {
    background-color: var(--color-contrast);
    padding: 12px;
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
    transition-duration: 0.3s;
}
.inscribir-asociado-container.select-sesiones .sesion:hover {
    background-color: #cbcbcb;
}
.inscribir-asociado-container.select-sesiones .sesion.selected {
    background-color: var(--color-primary);
    color: var(--color-background-contrast);
}
.inscribir-asociado-container.select-sesiones .sesion.selected:hover {
    background-color: var(--color-alternative);
}
.inscribir-asociado-container.select-sesiones .sesion.selected button.contrast {
    background-color: var(--color-text-secondary) !important;
    color: var(--color-text);
}
.inscribir-asociado-container.select-sesiones .sesion.selected .right-radius::after, .inscribir-asociado-container.select-sesiones .sesion.selected .right-radius::before, .inscribir-asociado-container.select-sesiones .sesion.selected .left-radius::after {
    background-color: var(--color-text) !important;
}
.inscribir-asociado-container.select-sesiones .sesion.sesion-evento.selected {
    background-color: var(--color-text);
    color: var(--color-background-contrast);
}
.inscribir-asociado-container.select-sesiones .sesion.sesion-evento.selected .checkbox {
    background-color: var(--color-text-secondary);
}
.inscribir-asociado-container.select-sesiones .sesion.sesion-evento.selected .checkbox::after, .inscribir-asociado-container.select-sesiones .sesion.sesion-evento.selected .checkbox ::before {
    background-color: var(--color-text);
}
.inscribir-asociado-container.select-sesiones.disabled {
    cursor: not-allowed;
    user-select: none !important;
}
.inscribir-asociado-container.select-sesiones * {
    font-weight: 600;
}
.inscribir-asociado-container.select-sesiones button.contrast {
    height: 2rem;
    width: 2.2rem;
    padding: 0;
    background-color: var(--color-background-contrast) !important;
}
.inscribir-asociado-container.select-sesiones button.contrast:hover {
    border: none;
}
.inscribir-asociado-container.select-sesiones button.contrast ~ input {
    width: 3rem !important;
    padding: 0 1rem;
    flex: 0 1 auto !important;
    border: none !important;
}
.inscribir-asociado-container.select-sesiones button.contrast.left-radius {
    border-radius: 5px 0 0 5px !important;
}
.inscribir-asociado-container.select-sesiones button.contrast.right-radius {
    border-radius: 0 5px 5px 0 !important;
}
.inscribir-asociado-container.select-sesiones button.contrast.left-radius::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 4px;
    background-color: var(--color-text);
    border-radius: 2px;
}
.inscribir-asociado-container.select-sesiones button.contrast.right-radius::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 2px;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 4px;
    background-color: var(--color-text);
    border-radius: 2px;
}
.inscribir-asociado-container.select-sesiones button.contrast.right-radius::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translate(-50%, -50%);
    width: 4px;
    height: 10px;
    background-color: var(--color-text);
    border-radius: 50%;
}
.sesion-card-icon {
    margin-top: -2px;
    height: 1.8rem;
    padding: 0.1rem;
    background-color: var(--color-background-contrast);
    border-radius: 50px;
}
button.contrast {
    height: 2rem;
}
button.contrast ~ input {
    height: 2rem;
    flex: 0 1 auto !important;
}
.carnet-checkbox {
    position: relative;
    appearance: none;
    width: 20px !important;
    padding: 0 !important;
    height: 20px !important;
    border-radius: 5px !important;
    background-color: var(--color-background-primary) !important;
    border: none !important;
    cursor: pointer;
    z-index: 0;
}
.carnet-checkbox:focus {
    outline: none !important;
}
.carnet-checkbox.disabled {
    background-color: transparent !important;
    cursor: not-allowed;
}
.carnet-checkbox.default {
    background-color: var(--color-background-primary) !important;
}
.carnet-checkbox.default::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 13px;
    height: 4px;
    background-color: var(--color-contrast-alternative);
    border-radius: 2px;
}
.carnet-checkbox:checked:not(.default) {
    background-color: var(--color-primary-contrast) !important;
}
.carnet-checkbox:checked:not(.default)::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 13px;
    height: 4px;
    background-color: var(--color-primary);
    border-radius: 2px;
}
.carnet-checkbox:checked:not(.default)::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 13px;
    height: 4px;
    background-color: var(--color-primary);
    border-radius: 2px;
}
.anotacion-tabla {
    margin-top: 1rem;
    font-weight: 600;
}
.checkbox {
    height: 20px;
    width: 20px;
    margin: 0;
    margin-left: auto;
    aspect-ratio: 1;
    background-color: var(--color-background-primary);
    border-radius: 5px;
}
.checkbox::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 4px;
    background-color: var(--color-text);
    border-radius: 2px;
}
.checkbox::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 4px;
    height: 10px;
    background-color: var(--color-text);
    border-radius: 50%;
}
.selected .checkbox {
    background-color: var(--color-primary-contrast);
}
.selected .checkbox::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10px;
    height: 4px;
    background-color: var(--color-primary);
    border-radius: 2px;
}
.selected .checkbox::before {
    content: none;
}
.inscribir-asociado__table--wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex: 1 1 auto;
    min-height: 200px;
    margin-top: 30px;
}
.guardado {
    display: flex;
    gap: 2px;
    color: var(--color-contrast-alternative) !important;
}
.guardado.red {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.2rem;
    line-height: 1.4;
    color: var(--color-primary) !important;
}
.guardado.red svg * {
    fill: var(--color-primary) !important;
}
.guardado svg {
    width: 18px;
    height: 18px;
}
.guardado svg * {
    fill: var(--color-contrast-alternative);
}
.inscribir-otro-asociado {
    margin-top: 30px;
}
.apuntado {
    box-shadow: inset 8px 0 0 0 var(--color-primary);
    background-color: var(--color-harmonic);
}
.apuntado:hover {
    background-color: var(--color-background-primary);
}
.apuntado .table-button a {
    background-color: #e8dcdf;
}
.apuntado .table-button a:hover {
    background-color: #dccace;
}
.apuntado input[type="checkbox"] {
    position: relative;
    appearance: none;
    width: 20px !important;
    padding: 0 !important;
    height: 20px !important;
    border-radius: 5px !important;
    background-color: var(--color-background-contrast) !important;
    border: none !important;
    cursor: pointer;
    z-index: 0;
}
.apuntado input[type="checkbox"]:focus {
    outline: none;
}
.apuntado input[type="checkbox"].disabled {
    background-color: transparent !important;
    cursor: not-allowed;
}
.apuntado input[type="checkbox"]:checked {
    background-color: var(--color-primary-contrast) !important;
}
.apuntado input[type="checkbox"]:checked::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 13px;
    height: 4px;
    background-color: var(--color-primary);
    border-radius: 2px;
}
.apuntado input[type="checkbox"]:checked::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 13px;
    height: 4px;
    background-color: var(--color-primary);
    border-radius: 2px;
}
.table-button .flex-row-item {
    flex-wrap: nowrap;
}
.table-inscripcion-mod {
    flex: 1 1 auto;
}
.table-inscripcion-mod .table-results--tr.apuntado {
    box-shadow: inset 8px 0 0 0 var(--color-primary);
    background-color: var(--color-harmonic);
}
.table-inscripcion-mod .table-results--tr.apuntado:hover {
    background-color: var(--color-background-primary);
}
.table-inscripcion-mod .table-results--tr.apuntado .table-button a {
    background-color: #e8dcdf;
}
.table-inscripcion-mod .table-results--tr.apuntado .table-button a:hover {
    background-color: #dccace;
}
.table-inscripcion-mod .table-results--tr.apuntado input[type="checkbox"] {
    position: relative;
    appearance: none;
    width: 20px !important;
    padding: 0 !important;
    height: 20px !important;
    border-radius: 5px !important;
    background-color: var(--color-background-contrast) !important;
    border: none !important;
    cursor: pointer;
    z-index: 0;
}
.table-inscripcion-mod .table-results--tr.apuntado input[type="checkbox"]:focus {
    outline: none;
}
.table-inscripcion-mod .table-results--tr.apuntado input[type="checkbox"].disabled {
    background-color: transparent !important;
    cursor: not-allowed;
}
.table-inscripcion-mod .table-results--tr.apuntado input[type="checkbox"]:checked {
    background-color: var(--color-primary-contrast) !important;
}
.table-inscripcion-mod .table-results--tr.apuntado input[type="checkbox"]:checked::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 13px;
    height: 4px;
    background-color: var(--color-primary);
    border-radius: 2px;
}
.table-inscripcion-mod .table-results--tr.apuntado input[type="checkbox"]:checked::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    width: 13px;
    height: 4px;
    background-color: var(--color-primary);
    border-radius: 2px;
}
.button-icon {
    background-color: transparent;
    border: none;
    padding: 0;
    display: inline-flex;
    align-items: center;
}
.button-icon--icon {
    width: 16px;
    height: 16px;
    padding: 0;
}
.asociadosvista-edit {
    height: 2.2rem;
    margin-left: 0.5rem;
}
.asociadosvista-edit * {
    fill: var(--color-primary);
}
.tipo-sesion {
    font-weight: 300 !important;
    font-style: italic;
}
.estado-curso {
    background-color: var(--color-contrast);
    color: var(--color-text-secondary) !important;
    padding: 0.15rem 1rem;
    border-radius: var(--border-radius);
    font-weight: 500;
}
.quick-view-curso {
    padding: 0;
    margin: 0;
    height: 100%;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
.quick-view-curso * {
    margin: 0 !important;
}
.quick-view-curso h4 {
    margin: 0 0 5px 0 !important;
}
.quick-view-curso li.quick-view-curso__item {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    gap: 5px;
    border-radius: 20px;
    background-color: var(--color-background-primary);
    padding: 2rem;
}
.quick-view-curso li.quick-view-curso__item svg {
    margin-left: -5px;
    width: 2rem;
    height: 2rem;
}
.quick-view-curso li.quick-view-curso__item svg * {
    fill: var(--color-contrast-alternative);
}
.quick-view-curso li.quick-view-curso__item .date {
    font-style: italic;
}
.quick-view-curso li.quick-view-curso__item .location {
    font-weight: 500;
    color: var(--color-contrast-alternative);
}
.quick-view-curso li.quick-view-curso__item button {
    padding: 4px 12px;
    align-self: flex-end;
}
.list-view-curso {
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 0;
    width: 100%;
    height: 100%;
    margin: 0 !important;
}
.list-view-curso .cursos-lista {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 4px 0;
    gap: 5px;
}
.list-view-curso .cursos-lista svg {
    height: 2.25rem !important;
}
.list-view-curso .cursos-lista svg * {
    fill: var(--color-primary);
}
.list-view-curso .cursos-lista h4 {
    font-size: 1.4rem;
    white-space: pre-wrap;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    margin: 0;
}
.list-view-curso .cursos-lista p {
    font-size: 1.5rem;
    margin: 0 !important;
    line-height: 1rem;
}
.list-view-curso .cursos-lista svg {
    height: 2rem;
}
.list-view-curso .cursos-lista .date {
    display: flex;
    align-items: center;
}
.list-view-curso .cursos-lista .location {
    font-weight: bold;
    margin-top: 2px !important;
}
.ver-mas__lite {
    margin-top: auto;
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin-top: 1rem;
}
.red-fill {
    fill: var(--color-primary) !important;
}
.red-fill * {
    fill: var(--color-primary) !important;
}
.inscribir-button {
    padding-top: 20px !important;
}
.cards-wrapper {
    gap: 15px;
    position: relative;
}
.cards-wrapper .continput {
    position: absolute;
    bottom: -16px;
}
.cards-wrapper h4 {
    font-size: 1.5rem;
    line-height: 1rem;
    color: var(--color-harmonic);
}
.cards-wrapper .single-card-container {
    background-color: var(--color-background-primary);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
}
.cards-wrapper .single-card-container span {
    padding-left: 15px;
    padding-top: 10px;
    border-top: 1px solid var(--color-contrast-alternative);
}
.cards-wrapper .single-card-container div:has(>input[type="text"]) {
    padding: 2px 16px 16px 16px;
}
.cards-wrapper .single-card-container input[type="text"] {
    background-color: var(--color-background-contrast);
    border-radius: 5px;
}
.card-header {
    padding: 8px 8px 8px 15px;
    background-color: var(--color-primary);
    border-top-left-radius: var(--border-radius);
    border-top-right-radius: var(--border-radius);
}
.flex-grow > * {
    flex-grow: 1;
}
.flex-grow > * > * {
    width: 100%;
}
.multi-item-row {
    display: flex !important;
    flex-wrap: nowrap;
    justify-content: space-between;
}
.multi-item-row button {
    width: max-content;
}
.multi-item-row p {
    width: auto !important;
    text-align: left;
}
.modal-plantillas input {
    width: 100%;
}
.modal-plantillas .multi-item-row input {
    width: 80%;
}
/* Export Modal */
.modal-backdrop {
    backdrop-filter: blur(2px) !important;
    background-color: rgba(19, 19, 19, 0.5) !important;
    opacity: 1 !important;
}
.export-modal {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 50%;
    height: auto;
    flex-grow: 0 !important;
    position: absolute;
}
.export-modal .modal-dialog {
    position: relative;
}
.export-modal .modal-content {
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
}
.export-modal .modal-content .modal-body {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 30px;
    gap: 30px;
}
.export-modal .modal-content .modal-body .modal-body-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 30px;
}
.export-modal .modal-content .modal-body .modal-body-content .modal-subtitle {
    font-size: 1.5rem !important;
}
.export-modal .section-icon {
    padding: auto;
    height: 2.4rem;
}
.export-modal .section-icon path {
    fill: var(--color-text);
}
.export-modal h3 {
    margin: 0;
    font-size: 2.5rem;
    color: var(--color-text);
}
.export-modal h4 {
    margin: 0;
    font-size: 2rem;
    color: var(--color-text);
}
.export-modal .available-fields, .export-modal .available-aggs {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.export-modal .available-fields input[type="checkbox"], .export-modal .available-aggs input[type="checkbox"] {
    display: none;
}
.export-modal .available-fields input[type="checkbox"]:checked + label, .export-modal .available-aggs input[type="checkbox"]:checked + label {
    background-color: var(--color-primary);
    border: 1px solid var(--color-primary);
    color: var(--color-background-contrast);
}
.export-modal .available-fields label, .export-modal .available-aggs label {
    margin: 0;
}
.export-modal .selected-fields, .export-modal .selected-aggs {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
.export-modal .selected-fields .remove-field path, .export-modal .selected-aggs .remove-field path, .export-modal .selected-fields .remove-field polygon, .export-modal .selected-aggs .remove-field polygon {
    fill: text;
    transition-duration: 0.3s;
}
.export-modal .selected-fields:hover + path, .export-modal .selected-aggs:hover + path, .export-modal .selected-fields :hover + polygon, .export-modal .selected-aggs :hover + polygon {
    fill: var(--color-contrast-alternative);
}
.modal_close-icon {
    padding: 35px;
    width: 90px;
    top: 0;
    right: 0;
    position: absolute;
}
.modal_close-icon:hover {
    cursor: pointer;
}
.loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.loader {
    border: 2px solid var(--color-harmonic);
    border-top: 2px solid var(--color-primary);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    margin-bottom: 25px;
}
.precarga {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.precarga span {
    font-size: 1.5rem;
}
.precarga svg {
    position: absolute;
    width: 80px;
    height: 80px;
}
.precarga svg * {
    fill: var(--color-primary);
}
.loader-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    animation: OpacityPulse 1.5s infinite;
}
.circle-wrapper {
    position: relative;
}
.circle-1, .circle-2, .circle-3, .circle-4, .circle-5 {
    content: "";
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: var(--color-primary);
    animation: ScalePulse 1.5s infinite;
}
.circle-1 {
    top: 2px;
    left: -2px;
    transform-origin: 20% 20%;
}
.circle-2 {
    right: -4px;
    top: 2px;
    transform-origin: 80% 20%;
    animation-delay: 0.3s;
}
.circle-3 {
    right: 3px;
    top: -16px;
    transform-origin: 100% 60%;
    animation-delay: 0.6s;
}
.circle-4 {
    right: -12px;
    top: -31px;
    transform-origin: 50% 100%;
    animation-delay: 0.9s;
}
.circle-5 {
    right: -28px;
    top: -17px;
    transform-origin: 0% 60%;
    animation-delay: 1.2s;
}
@keyframes OpacityPulse {
    0% {
        opacity: 1;
   }
    50% {
        opacity: 0.75;
   }
    100% {
        opacity: 1;
   }
}
@keyframes ScalePulse {
    0% {
        transform: scale(1);
   }
    50% {
        transform: scale(1.2);
   }
    100% {
        transform: scale(1);
   }
}
/* Sección INICIO */
#inicio h3 {
    color: var(--color-contrast-alternative);
    font-size: 1em;
    font-style: oblique;
    margin: 0;
}
#inicio h2 {
    font-size: 2rem !important;
    margin-top: 0;
    margin-bottom: 0;
}
#inicio .onboarding h2 {
    font-size: 2rem;
    font-weight: 500;
    min-height: fit-content;
    margin-right: 1rem;
}
#inicio .onboarding a {
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    color: var(--color-primary);
}
#inicio .onboarding a:hover {
    cursor: pointer;
    color: var(--color-alternative);
}
#inicio .onboarding .flex-row-item {
    margin-bottom: 1rem;
}
.management .flex-row-item {
    margin-bottom: 0 !important;
}
#inicio .onboarding .active_user-edit {
    margin-left: 4px;
    height: 2rem;
}
#inicio .management .flex-row-item {
    flex-wrap: nowrap;
    align-items: center;
}
#inicio .management .active_user-rights {
    display: flex;
    gap: 4px;
    flex-direction: row;
}
#inicio .management li > * {
    align-items: flex-start;
    justify-content: flex-start;
}
#inicio .management h3::after {
    content: ",";
}
#inicio .management h3:last-of-type::after {
    content: "";
}
#inicio .management ul {
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
}
#inicio .management ul li {
    display: flex;
    gap: 12px;
    align-items: center;
}
#inicio .management ul svg {
    min-width: 2rem;
    width: 2rem;
    height: 2rem;
}
#inicio .management ul svg path {
    fill: var(--color-text);
}
#inicio .management ul select {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--color-text);
    background-color: transparent;
    border: none;
    /* max-width: calc(100% - 10px - 3rem); */
    padding: 8px 16px 8px 0;
    opacity: 1 !important;
    flex: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: auto;
}
#inicio .paid-fees ul {
    padding: 0;
    margin: 0;
    display: flex;
    gap: 8px;
}
#inicio .paid-fees ul li {
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 8px 18px 8px 24px;
    border-radius: 50px;
    opacity: 1;
}
#inicio .paid-fees ul li span {
    color: white;
    font-weight: 700;
}
#inicio .paid-fees ul li img {
    height: 2rem;
}
/* Sección ASOCIADOS */
#asociados .modal-wrapper form .user-avatar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
}
#asociados .modal-wrapper form .user-avatar img {
    width: 100px;
    height: 100px;
    border-radius: 100px;
}
#asociados .modal-wrapper form .user-avatar span {
    font-size: 2rem;
    font-weight: 700;
    width: auto;
}
#asociados .modal-wrapper form .associates-data {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}
#asociados .modal-wrapper form .associates-data .column-single {
    grid-column: span 1;
}
#asociados .modal-wrapper form .associates-data .column-duo {
    grid-column: span 2;
}
/* Sección CURSOS */
.section-icon.curso {
    width: 3rem;
    height: 3rem;
}
/* Sección CUOTAS */
#cuotas .table-results tr.clickable {
    box-shadow: inset 4px 0 0 0 var(--color-contrast);
}
/* #cuotas .table-results tr.clickable:hover {
    cursor: pointer;
} */
#cuotas .table-results tr.clickable.selected {
    box-shadow: inset 8px 0 0 0 var(--color-primary);
    background-color: var(--color-contrast);
}
#cuotas .table-results tr.clickable.selected svg.unvalidated * {
    fill: var(--color-contrast-alternative);
}
#cuotas .table-results tr.not-clickable * {
    color: var(--color-contrast);
}
#cuotas .table-results tr.not-clickable:hover {
    background-color: white !important;
}
#cuotas .table-results tr.not-clickable:hover .cell-td-button {
    background-color: var(--color-background-primary) !important;
}
#cuotas .table-results .validated polygon, #cuotas .table-results .validated path {
    fill: var(--color-primary);
}
#cuotas .table-results .unvalidated polygon, #cuotas .table-results .unvalidated path {
    fill: var(--color-contrast);
}
#cuotas .cuotas-filtro ul {
    display: flex;
    gap: 0.5rem;
    padding: 0;
    margin: 0;
    align-items: center;
}
#cuotas .cuotas-filtro li {
    font-weight: bold;
}
#cuotas .cuotas-filtro svg {
    width: 20px;
    height: 20px;
}
#cuotas .cuotas-filtro svg * {
    fill: var(--color-background-primary);
}
#cuotas .notificar .carnet-checkbox:not(.carnet-checkbox:checked) {
    background-color: var(--color-contrast) !important;
}
#cuotas .clickable:hover .carnet-checkbox:not(.carnet-checkbox:checked) {
    background-color: var(--color-contrast) !important;
}
#cuotas .notificar.clickable:hover .carnet-checkbox:not(.carnet-checkbox:checked) {
    background-color: var(--color-contrast-alternative) !important;
}
button:has(.fees-filter__type-1) {
    background-color: var(--color-primary);
    border: 1px solid transparent !important;
}
button:has(.fees-filter__type-1) * {
    fill: var(--color-background-primary) !important;
}
button:has(.fees-filter__type-2) {
    background-color: var(--color-background-primary);
    border: 1px solid transparent !important;
}
button:has(.fees-filter__type-2) * {
    fill: var(--color-contrast-alternative) !important;
}
button:has(.fees-filter__type-2):hover {
    background-color: #f9f9f9;
}
button:has(.fees-filter__type-3) {
    opacity: 0.5;
    background-color: var(--color-primary-contrast);
    border: 1px solid transparent !important;
}
button:has(.fees-filter__type-3) * {
    fill: var(--color-primary) !important;
}
button:has(.fees-filter__type-3):hover {
    background-color: #f3c9d8;
}
/* Sección LISTADO */
#listado header {
    display: flex;
    justify-content: space-between;
}
#listado header div {
    display: flex;
    gap: 8px;
    align-items: center;
}
#listado .vista-filtering {
    display: flex !important;
    justify-content: space-between;
    flex-direction: row;
}
#listado .vista-filtering > div {
    display: flex !important;
    gap: 8px;
}
#listado .vista-listado {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#listado .vista-listado > * {
    flex: 1;
}
#listado .vista-listado h2 {
    margin: 0;
}
#listado .vista-listado span {
    text-align: center;
}
#listado .vista-listado div {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
}
.modal-wrapper .backdrop {
    padding: 0 !important;
}
.modal-wrapper .backdrop > *:not(.modal_close-icon) {
    padding-left: 30px;
    padding-right: 30px;
}
.modal-wrapper .backdrop > *:not(.modal_close-icon):first-child {
    padding-top: 30px;
}
.modal-wrapper .backdrop > *:not(.modal_close-icon):last-child {
    padding-bottom: 30px;
}
/* Código anterior */
.dashboard .nav button span {
    margin-right: 5px;
}
.ultbtn img {
    margin-right: 5px;
}
.dashboard .col-xs-2 {
    height: -webkit-fill-available;
    padding: 15px 20px 0 20px;
    border-right: 1px solid #d1d1d1;
}
.titular {
    display: flex;
    align-items: center;
}
.titular img {
    width: 22px;
}
.titular h2 {
    font-size: 1em;
    padding: 0;
    margin: 0 0 0 5px;
}
.cont_nav_arrows {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.nav_arrows ul {
    display: flex;
}
.dashboard .col-xs-12 {
    padding: 0 !important;
}
.dashboard .col-xs-12.scroll {
    padding-right: 5px !important;
}
.dashboard .col-xs-10 {
    padding: 0 25px;
}
.dashboard .col-xs-6 {
    padding: 25px 0 0 0 !important;
}
.dashboard .tabladatos {
    margin: 0 0 0 0 !important;
    width: 100%;
}
.dashboard .tablaresultados {
    width: 100%;
}
.dashboard .tabladatos td {
    width: 10%;
}
.dashboard .tablaresultados td {
    width: 10%;
}
.dashboard .tablaresultados td:last-child ul li {
    padding: 0;
    float: right;
}
.dashboard .tablaresultados td:last-child ul li button {
    padding: 0;
}
.dashboard .tablaresultados ul {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    margin-right: 10px;
    align-content: center;
}
.dashboard .tablaresultados tr td {
    padding-left: 10px;
    height: 40px;
    max-width: 10px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
    align-content: center;
}
.dashboard .tablaresultados tr:hover {
    background-color: #f5f5f5;
}
.dashboard .tablaresultados tr.selected {
    color: #a30139;
    font-weight: 500;
    background-color: #f0f0f0;
    box-shadow: inset 4px 0 0 0 #a30139;
}
.justify-content-end {
    justify-content: flex-end !important;
}
.scroll {
    overflow-y: auto;
    max-height: 520px;
}
.scroll::-webkit-scrollbar {
    width: 0.5em;
    background: #d1d1d1;
}
.table-container::-webkit-scrollbar:horizontal {
    height: 0.5em;
}
.scroll::-webkit-scrollbar-thumb {
    background: #000;
}
/*montar con desarrollo*/
/* .dashboard .tablaresultados td:last-child ul li:last-child {
    padding: 0 5% 0 0;
}
*/
/* .dashboard .tabladatos td:last-child {
    padding: 0 5% 0 0;
}
*/
/* .mgr10 {
    padding-right: 10px!important;
}
*/
/*****/
.subformulario {
    padding: 0 0 0 0 !important;
}
.tabladatos td {
    padding-left: 10px;
    color: #a3013b;
    font-weight: bold;
}
.texto_rojo {
    color: #a3013b;
    font-weight: bold;
}
.nav img {
    width: 20px;
    margin-right: 5px;
}
.nav ul img {
    height: 20px;
}
.ultbtn img {
    width: 25px;
    margin-right: 5px;
}
.dashboard .tablaresultados td:last-child ul li img {
    width: 20px;
}
.tablaresultados td img.arrow {
    width: 13px;
}
.nav_arrows img {
    width: 17px;
}
.nav_arrows button {
    padding: 0 !important;
}
.openmenu, .closemenu {
    display: flex;
    justify-content: flex-end;
}
.openmenu img {
    width: 21px;
}
.closemenu img {
    width: 21px;
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
}
/* PRECARGA 
/* .precarga {
    background: #ffffff82;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.precarga img {
    width: 170px;
    height: auto;
}
.fixposition .precarga {
    background: #fff !important;
    position: relative !important;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nuevaclase .precarga {
    background: #fff !important;
}
*/
/*NAV BAR */
#content {
    width: 100%;
    padding: 40px 20px 20px 0;
    transition: all 0.3s;
}
.navbar {
    width: 20px;
    height: 20px;
    padding: 0 !important;
    margin: -12px 0 0 202px !important;
    background: none !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important;
    background: url("../img/menu.svg") 0 0 no-repeat !important;
}
.navbar .btn {
    width: 20px;
    height: 20px;
    border-color: none !important;
    border: none !important;
    padding: 0 !important;
    box-shadow: none;
    background: none !important;
}
.navbar .btn:focus {
    border: none !important;
    padding: 0 !important;
    box-shadow: none;
    outline: none !important;
}
.cambiaicono {
    background: url("../img/close.svg") 0 0 no-repeat !important;
}
.dashboard .col-xs-12.contresto {
    padding-bottom: 10px !important;
}
.contresto.col-xs-12 .col-xs-12:first-child {
    padding-right: 10px !important;
}
.col-xs-12.divtabla {
    height: 100% !important;
    overflow: auto;
    padding-right: 12px !important;
}
.modalcuotas .nuevazona h4 {
    margin-bottom: 25px;
    margin-top: 0;
}
.loginidiomas button {
    -webkit-transition: none;
    -moz-transition: none;
    -ms-transition: none;
    -o-transition: none;
    transition: none;
}
.loginidiomas button:hover {
    opacity: 1 !important;
}
.navbarmovil {
    display: none !important;
}
.dojo_container {
    display: flex;
    flex: 1 0 0;
    flex-direction: row;
    align-items: center;
    gap: 28px;
    font-weight: 600;
    font-size: 1.5rem;
    line-height: 1rem;
    padding: 8px 18px 8px 0;
    color: var(--color-text);
    text-overflow: ellipsis;
    overflow: hidden;
    text-wrap: nowrap;
}
.dojo_container svg {
    width: 3rem;
    height: 3rem;
}
.dojo_container svg * {
    fill: var(--color-text);
}
.horizontal-divider {
    border-top: 1px solid var(--color-contrast);
    margin: 15px 0;
}
.horizontal-divider.no-margin {
    margin: 0;
}
.modal-container.content-wrapper {
    overflow: hidden;
}
.modal-header, .modal-footer {
    padding: 30px;
}
.modal-footer {
    margin-top: auto;
}
.modal-content-scroll {
    /* display: flex;
    flex-direction: column;
    */
    gap: 30px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 30px;
    padding-bottom: 30px;
}
.modal-content-scroll.inscripciones {
    padding-top: 0 !important;
    gap: 0 !important;
}
.padding-top {
    padding-top: 30px;
}
.padding-bottom {
    padding-bottom: 30px;
}
.disable-interaction {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    user-select: none;
    background-color: var(--color-background-contrast);
    opacity: 0.5;
    z-index: 9999;
}
.relative-container {
    position: relative !important;
}
.error {
    position: absolute;
    color: var(--color-primary);
    font-weight: 500 !important;
    font-size: 1rem;
    margin: 0 !important;
}
.mobile {
    display: none;
}
.menu-icon {
    width: 4rem;
    height: 4rem;
    border: none;
    opacity: 1;
    align-self: end;
}
button:has(.menu-icon) {
    border: none;
    background: none;
    padding: 0 !important;
}
.precarga {
    background: var(--color-background-contrast);
    z-index: 9999;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    font-size: 2rem;
    font-weight: 800;
}
.precarga > img {
    width: 15%;
    max-width: 80px;
}
.table-container:has(>.table-inscripcion-mod) {
    flex: 1 1 auto;
}
.asociado-search {
    outline: none !important;
}
.desktop-hidden .selectidiomas {
    display: none;
}
.toggle-switch__wrapper {
    display: flex;
    align-items: center;
    padding: 4px;
    height: 20px;
    width: 40px;
    border-radius: 50px;
    background-color: var(--color-background-contrast);
    cursor: pointer;
}
.toggle-switch__handle {
    height: 12px;
    width: 12px;
    border-radius: 50px;
    background-color: var(--color-primary);
}
.sesion-s, .sesion-e {
    transition-duration: 0.3s;
}
.grados-listing {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.grados-listing .grados-fecha {
    flex: 3 0 0 !important;
    border-radius: 5px !important;
}
.grados-listing .grados-nhoras {
    flex: 1 0 0 !important;
    border-radius: 5px !important;
}
.cinturon-label {
    text-transform: uppercase;
    font-style: italic;
    font-weight: bold;
}
.grados-listing {
    padding: 12px;
    background-color: var(--color-contrast);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
    transition-duration: 0.3s;
}
.grados-listing .grado-color {
    width: 1.8rem !important;
    height: 1.8rem !important;
    border: 2px solid white;
}
.grados-listing:hover {
    background-color: #cbcbcb;
}
.grados-listing.selected {
    background-color: var(--color-primary);
    color: var(--color-background-contrast);
}
.grados-listing.selected:hover {
    background-color: var(--color-alternative);
}
.grados-listing:hover {
    background-color: #cbcbcb;
}
.grados-listing.selected {
    color: var(--color-background-contrast);
}
.grados-listing.selected:hover {
    background-color: var(--color-alternative);
}
.grados-listing.selected button.contrast {
    background-color: var(--color-text-secondary) !important;
    color: var(--color-text);
}
.grados-listing.selected .right-radius::after, .grados-listing.selected .right-radius::before, .grados-listing.selected .left-radius::after {
    background-color: var(--color-text) !important;
}
.grados-listing.selected input[type="date"] {
    border: none;
    font-weight: 400;
    color: var(--color-primary);
    background-color: var(--color-primary-contrast);
}
.grados-listing.dan.selected {
    background-color: var(--color-text);
    color: var(--color-background-contrast);
}
.grados-listing.dan.selected .checkbox {
    background-color: var(--color-text-secondary);
}
.grados-listing.dan.selected .checkbox::after, .grados-listing.dan.selected .checkbox ::before {
    background-color: var(--color-text);
}
.grados-listing.dan.selected input[type="date"] {
    border: none;
    font-weight: 400;
    color: var(--color-text);
    background-color: var(--color-text-secondary);
}
.grados-listing.titulos .grados-fecha {
    height: 2rem;
    padding: 0.5rem;
    flex-grow: 1;
}
.titulo-icon {
    width: 1.8rem;
    height: 1.8rem;
}
.titulo-icon.black * {
    fill: var(--color-text) !important;
}
.titulo-icon * {
    fill: var(--color-background-contrast) !important;
}
.grado-estado-validacion {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background-color: var(--color-primary-contrast);
    color: var(--color-primary) !important;
    padding: 0.15rem 0.5rem 0.15rem 0.5rem;
    border-radius: var(--border-radius);
    font-weight: 500;
    height: 20px;
    line-height: 18px;
}
.dan .grado-estado-validacion {
    background-color: var(--color-text-secondary);
    color: var(--color-text) !important;
}
.grados-error {
    position: relative !important;
    bottom: 0 !important;
    top: unset !important;
    color: var(--color-background-contrast);
}
.cinturones-intermedios-toggle {
    width: fit-content;
    padding: 10px 20px 10px 10px;
    border-radius: 10px;
    background-color: var(--color-contrast);
    justify-content: flex-end;
    cursor: pointer;
    font-weight: bold;
    margin-left: auto;
}
.cinturones-intermedios-toggle.selected {
    background-color: var(--color-primary);
    color: var(--color-background-contrast);
}
.sesion-e {
    background-color: var(--color-harmonic);
}
.sesion-e .toggle-switch__wrapper {
    background-color: var(--color-primary);
    justify-content: flex-end;
}
.sesion-e .toggle-switch__handle {
    background-color: var(--color-harmonic);
}
.sesion-e h4 {
    color: var(--color-primary);
}
/* .clickable {
    cursor: pointer;
} */
.nav-link__button {
    line-height: 2.2rem;
}
tr.disable {
    opacity: 0.5;
    background-color: var(--color-background-primary);
}
.go-to {
    background-color: var(--color-background-primary);
    border: none;
    border-radius: 50px;
    display: flex;
    align-items: center;
    margin: 0 !important;
    padding: 2px 0px 2px 12px;
    transition: background-color 0.3s;
}
.go-to:hover {
    background-color: var(--color-contrast);
}
.go-to__text {
    color: var(--color-text);
}
.go-to__icon {
    width: 22px !important;
    height: 22px !important;
    padding: 0;
    transform: rotate(-45deg);
}
@media screen and (max-width: 1200px) {
    .filtering.flex-row-item {
        flex-wrap: wrap;
    }
    .grid {
        grid-template-columns: repeat(1, 1fr);
    }
    header h1 {
        display: none;
    }
}
@media screen and (max-width: 767px) {
    .toggle-perms__container {
        flex-direction: column !important;
    }
    .toggle-perms__container label {
        width: 100% !important;
    }
    .active_user-rights {
        display: flex;
        gap: 4px;
        flex-direction: column !important;
   }
    .grid.card {
        grid-template-columns: repeat(1, 1fr);
   }
    .desktop-hidden .selectidiomas {
        display: block;
        margin-bottom: 1rem;
   }
    .inscribir-asociado__img {
        width: 100%;
        height: fit-content;
        max-height: unset;
        min-width: 100%;
        max-width: 100%;
   }
    .inscribir-asociado__img .curso-img {
        width: 100% !important;
        height: auto !important;
   }
    .inscribir-asociado-container--row {
        flex-direction: column;
   }
    .mobile {
        display: flex;
   }
    .mobile-hidden, .desktop {
        display: none !important;
   }
    header {
        height: auto !important;
   }
    .flex-row-item, .page-navigation {
        justify-items: center;
        gap: 2rem;
   }
    .user-avatar__name {
        justify-content: flex-start;
        gap: 2rem;
   }
    .space-between button {
        margin-left: auto;
   }
    .flex-wrapper.button-container {
        justify-content: flex-end;
   }
    .flex-wrapper:has(>.input-calculated) {
        flex-wrap: nowrap !important;
   }
    .search, .cuotas-filtro, .cuotas-filtro .n-results, .cuotas-filtro .n-results .view-input {
        width: 100%;
   }
    #sidebar {
        z-index: 1;
   }
    #sidebar:not(.active-sidebar) {
        display: none;
   }
    .active-sidebar {
        position: absolute;
        width: calc(100% - 60px);
        height: calc(100% - 60px);
        display: flex;
   }
    .app.nav-menu {
        border-top: none !important;
        padding: 2rem !important;
   }
    .nav-menu {
        gap: 30px !important;
   }
    .nav-menu li a button {
        gap: 20px !important;
        align-items: center;
   }
    .nav-menu svg {
        height: 2.8rem !important;
        width: 2.8rem !important;
   }
    .nav-menu * {
        font-size: 1.6rem;
   }
    .nav-menu .notification {
        font-size: 1.7rem;
        padding: 1rem;
   }
    .content {
        grid-column: 0.1428571429;
   }
    .selectidiomas ul {
        top: 4px !important;
   }
    .associates-data {
        display: flex !important;
        flex-direction: column;
   }
    .active_user-avatar button {
        align-items: center;
        font-size: 1.7rem;
   }
    .active_user-avatar button img {
        width: 2.8rem;
        height: 2.8rem;
   }
    .table-headers th, .table-headers .table-headers--th, .table-results td, .table-results .table-results--td {
        flex: 1 0 0;
        min-width: 80px;
   }
    .tiny-column {
        min-width: 0 !important;
   }
}
