#landing-wrapper {
	scroll-behavior: smooth;
	font-weight: 300;
}
#landing-header {
	position: fixed;
	top: 0;
	height: 70px;
	width: 100%;
	background-color: #fff;
	z-index: 1000;
}
#landing-header nav {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	height: 100%;
	padding-right: 15px;
}
#landing-header nav .nav-login a {
	color: white;
}
#landing-header .nav-logo {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
	height: 100%;
	padding: 15px;
}
#landing-header .nav-logo .logo-img {
	height: 40px;
}
#landing-header .nav-logo span {
	font-size: 10px;
	width: max-content;
}
#landing-header .nav-menu {
	height: 100%;
	display: flex;
	flex-direction: row;
	width: fit-content;
	gap: 0 !important;
}
#landing-header .nav-menu li {
	height: 100%;
	display: flex;
	align-items: center;
	border-bottom: 4px solid transparent;
}
#landing-header .nav-menu li a:hover {
	background-color: transparent;
}
#landing-header .nav-menu li a.active {
	background-color: transparent;
}
#landing-header .nav-menu li:has(a.active) {
	border-bottom: 4px solid var(--color-primary);
}
#landing-main {
	width: auto;
	height: auto;
	padding: 0;
	margin: 0;
	display: block;
	max-width: none;
}
#landing-main section {
	padding: 100px 0;
	background-color: #f2f2f2;
	/* Offset para compensar el navbar */
}
#landing-main section:nth-of-type(odd) {
	color: #f2f2f2;
	background-color: #333;
}
#landing-main section:before {
	content: "";
	display: block;
	height: 70px;
	margin-top: -70px;
	visibility: hidden;
}
#landing-main section .section-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 20px;
	width: 80%;
	max-width: 1200px;
	margin: 0 auto;
}
#landing-main section .section-content .flex-row-item.contacto {
	width: 100%;
	align-items: flex-start;
}
#landing-main section .section-content .flex-row-item > * {
	flex: 1 0 0;
}
#landing-main section .publicacion-grid {
	width: 100%;
	row-gap: 0;
	height: 550px;
}
#landing-main section .publicacion-container {
	display: flex;
	flex-direction: column;
	height: 100% !important;
	overflow: hidden;
}
#landing-main section .publicacion-img {
	object-fit: cover;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px 5px 0 0;
	overflow: hidden;
	flex: 1 1 auto;
}
#landing-main section .publicacion-img img {
	width: 100%;
	object-fit: cover;
	min-width: 100%;
	min-height: 100%;
}
#landing-main section .publicacion-desc {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	padding: 10px;
	background-color: #f2f2f2;
	color: #333;
	border-radius: 0 0 5px 5px;
	box-shadow: 0 0 10px -5px #000 44;
}
#landing-main section .amazon-link {
	height: 20px;
	align-self: flex-end;
}
#landing-main section .amazon-link img {
	height: 100%;
}
#landing-main section h2 {
	font-size: 20px;
	margin-bottom: 0;
	font-weight: bold;
	font-style: italic;
	padding-bottom: 20px;
	border-bottom: 1px solid #aaa;
	margin-top: 0;
	width: 100%;
}
#landing-main section h3 {
	font-size: 16px;
	margin-bottom: 20px;
	font-weight: bold;
	width: 100%;
}
#landing-main section .section-text p,
#landing-main section .section-text ul,
#landing-main section .section-text ol {
	margin: 0 !important;
}
#landing-main section .section-text p:not(:last-child),
#landing-main section .section-text ul:not(:last-child),
#landing-main section .section-text ol:not(:last-child) {
	margin-bottom: 20px !important;
}
#landing-main section ul,
#landing-main section ol {
	list-style: disc;
	padding-left: 30px;
}
#landing-main section .contacto {
	padding-bottom: 20px;
	border-bottom: 1px solid #aaa;
}
#landing-main section .contacto label {
	font-weight: 300;
	color: #aaa;
}
#landing-main section .contacto input,
#landing-main section .contacto textarea {
	padding-left: 5px;
	min-height: 28px;
	border: none;
	border-radius: 5px;
}
#landing-main section .contacto textarea {
	height: 100px;
}
#landing-main section .contacto form > * {
	position: relative;
}
#landing-main section .contacto form > * .error {
	position: absolute;
	bottom: -2rem;
}
#landing-main section .contacto .cta {
	width: fit-content;
	flex: 0 0 auto;
}
#landing-main section .contacto .success-message {
	margin: 0 !important;
	color: var(--color-primary);
}
#eventos {
	padding: 100px 0;
	background-color: #f2f2f2;
}
#eventos .section-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 20px;
	width: 80%;
	max-width: 1200px;
	margin: 0 auto;
}
#eventos h3 {
	font-size: 16px;
	font-weight: bold;
	margin: 0 !important;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre-wrap;
}
.imagen-evento {
	min-width: fit-content;
	width: fit-content;
	height: 100%;
	border-radius: 5px 0 0 5px;
	overflow: hidden;
	position: relative;
}
.imagen-evento .imagen-evento__item {
	width: auto;
	height: 100%;
	object-fit: contain;
}
.no-image-container {
	position: relative;
	padding: 6%;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
}
.no-image-container img {
	width: 100%;
	height: fit-content;
	max-height: 60%;
}
.no-image-container .no-image-content {
	padding-top: 6%;
	flex: 1;
	display: flex;
	flex-direction: column;
	position: relative;
}
.no-image-container .no-image-content .titulo-curso {
	font-size: 50%;
	font-weight: bold;
	-webkit-line-clamp: 2;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
}
.no-image-container .no-image-content .ciudad-curso {
	font-size: 30%;
	font-style: italic;
}
.no-image-container .no-image-content .fecha-curso {
	font-size: 20%;
	font-style: italic;
}
.no-image-container .no-image-content .acae-logo {
	width: 10%;
	height: 30%;
	margin: 0 auto;
	margin-top: auto;
}
.eventos-showcase .titulo-curso {
	font-size: 18px !important;
}
.eventos-showcase .ciudad-curso {
	font-size: 14px !important;
}
.eventos-showcase .fecha-curso {
	font-size: 12px !important;
}
.evento-content {
	padding: 20px;
}
.evento-content .evento-text {
	height: 100%;
	overflow: hidden;
}
.ver-evento {
	border: none;
	background-color: transparent;
	padding: 0 !important;
	font-size: 12px;
	line-height: 12px;
}
.eventos-slider-wrapper {
	display: flex;
	flex-direction: column;
	gap: 20px;
	width: 100%;
}
.eventos-slider {
	width: 100%;
}
.eventos-card-wrapper {
	display: flex;
	gap: 21px;
	padding: 0 !important;
	margin: 0 !important;
	height: 100%;
}
.evento {
	display: flex;
	cursor: pointer;
	height: 200px;
	width: calc(100% / 3);
}
.evento.selected {
	box-shadow: 0 0 10px -5px #000 44;
}
.evento:not(.selected) {
	opacity: 0.25;
	filter: saturate(0);
	transition: all 0.5s;
}
.evento:not(.selected):hover {
	opacity: 0.75;
	filter: saturate(1) contrast(1);
}
.evento,
.eventos-showcase {
	display: flex;
	flex-direction: row;
	background-color: #fff;
	list-style: none;
	border-radius: 10px;
}
.evento .date,
.eventos-showcase .date {
	font-size: 12px;
	font-style: italic;
	margin: 0 !important;
}
.evento .ubicacion,
.eventos-showcase .ubicacion {
	opacity: 0.5;
	font-weight: normal;
}
.evento .ubicacion svg,
.eventos-showcase .ubicacion svg {
	max-width: fit-content;
	height: 20px;
}
.evento h4,
.eventos-showcase h4,
.evento p,
.eventos-showcase p {
	margin: 0 !important;
}
.evento button,
.eventos-showcase button {
	align-self: flex-end;
}
.eventos-showcase {
	width: 100%;
	display: flex;
	flex-direction: row;
	background-color: #fff;
	border-radius: 10px;
	overflow: hidden;
	height: 600px;
}
.eventos-showcase .eventos-showcase-img {
	height: 100%;
	aspect-ratio: 0.7070707071;
}
.eventos-showcase .eventos-showcase-img .eventos-showcase-img__item {
	width: 100%;
}
.eventos-showcase .eventos-showcase-content-wrapper {
	padding: 20px;
	width: 100%;
}
.eventos-showcase .eventos-showcase-content-wrapper .eventos-showcase-content {
	display: flex;
	flex-direction: column;
	gap: 20px;
	height: 100%;
	overflow: hidden;
}
.eventos-showcase .eventos-showcase-content-wrapper .eventos-desc {
	flex: 1 0 0;
	overflow: auto;
	background-color: #f2f2f2;
	padding: 10px;
	border-radius: 5px;
	white-space: pre-wrap;
}
#landing-footer {
	background-color: #333;
	color: #f2f2f2;
	padding: 100px 0;
}
#landing-footer .footer-content-wrapper {
	width: 80%;
	max-width: 1200px;
	margin: 0 auto;
	align-items: flex-start;
	padding-bottom: 20px;
	border-bottom: 1px solid #aaa;
	margin-bottom: 20px;
}
#landing-footer .footer-legal {
	width: 80%;
	max-width: 1200px;
	margin: 0 auto;
	justify-content: space-between;
	color: #aaa;
}
#landing-footer .footer-legal .footer-link {
	color: #aaa !important;
}
#landing-footer .footer-logo {
	flex: 2 0 0;
	height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
}
#landing-footer .footer-logo img {
	height: 100%;
	border-radius: 200px;
}
#landing-footer .footer-logo span {
	font-size: 10px;
	width: max-content;
}
#landing-footer .footer-contacto {
	flex: 1 0 0;
}
#landing-footer .footer-contacto * {
	font-size: 12px;
}
#landing-footer .footer-contacto p {
	margin: 0 !important;
}
#landing-footer .footer-menu {
	flex: 0 0 auto;
}
#landing-footer .footer-menu nav {
	display: block !important;
}
#landing-footer .footer-menu a {
	color: #f2f2f2;
	font-weight: 300;
}
.mapa-ametsuchi {
	width: 100%;
	height: 400px;
	border-radius: 5px;
	box-shadow: 0 0 10px -5px #000 44;
}
section:has(.nuestra-historia) {
	background-image: url("../img/nuestra-historia-background.png");
	background-repeat: no-repeat;
	background-position: 110% 100%;
	background-size: contain;
	position: relative;
}
#hero-section {
	background-image: url("../img/hero-background.jpg");
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	position: relative;
	min-height: 600px;
	margin-top: 70px;
}
.hero-content__kanji {
	height: 40%;
	position: absolute;
	top: 20px;
	right: 20px;
}
.hero-content__kanji img {
	height: 100%;
}
.hero-content__text {
	display: flex;
	flex-direction: column;
	position: absolute;
	bottom: 20px;
	left: 20px;
	font-size: 12px;
}
.text-italic {
	font-style: italic;
}
.text-bold {
	font-weight: bold;
}
.menu-toggle-landing {
	display: none;
	align-items: center;
	width: 30px;
}
@media screen and (max-width: 1200px) {
	.ocultar {
		display: none !important;
	}
	.menu-toggle-landing {
		display: flex;
	}
	.eventos-showcase {
		flex-direction: column;
		height: auto;
	}
	.publicacion-grid {
		height: auto !important;
		max-height: auto !important;
		grid-template-columns: repeat(3, 1fr) !important;
	}
	.eventos-card-wrapper {
		flex-direction: column;
	}
	.evento {
		width: 100%;
		height: 300px;
	}
	.evento .imagen-evento {
		width: auto !important;
	}
	.evento .imagen-evento .imagen-evento__item {
		height: 100% !important;
		width: auto;
	}
	.nav-menu:not(.app) {
		flex-direction: column !important;
		align-items: center;
		background-color: white;
		height: fit-content !important;
		position: absolute;
		top: 70px;
		width: 100% !important;
		border-top: 1px solid var(--color-text);
	}
	.nav-menu:not(.app) .nav-li {
		width: 100%;
	}
	.nav-menu:not(.app) .nav-li:has(a.active) {
		background-color: var(--color-harmonic);
		border-bottom: 4px solid transparent !important;
	}
	.nav-menu:not(.app) .nav-link {
		width: 100%;
		text-align: center;
		justify-content: center;
	}
}
@media screen and (max-width: 767px) {
	.publicacion-grid {
		grid-template-columns: repeat(1, 1fr) !important;
		row-gap: 30px !important;
	}
	.evento {
		height: auto !important;
	}
	.imagen-evento {
		display: none;
	}
	.contacto {
		flex-direction: column !important;
	}
	.contacto form.flex-column {
		width: 100%;
	}
}
