.carnetacae__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.carnetacae__cta {
    height: 25px;
    padding: 1rem;
}
.carnetacae__cortes {
    position: relative;
    width: fit-content;
    height: calc(12mm + 59.98mm);
    width: calc(12mm + 91.6mm);
    display: flex;
    justify-content: center;
    align-items: center;
}
.carnetacae__marcas-corte--superior {
    position: absolute;
    width: 85.6mm;
    height: 6mm;
    top: 0;
    border: 1px solid black;
    border-width: 0 1px;
}
.carnetacae__marcas-corte--inferior {
    position: absolute;
    width: 85.6mm;
    height: 6mm;
    bottom: 0;
    border: 1px solid black;
    border-width: 0 1px;
}
.carnetacae__marcas-corte--derecho {
    position: absolute;
    height: 53.98mm;
    width: 6mm;
    right: 0;
    border: 1px solid black;
    border-width: 1px 0;
}
.carnetacae__marcas-corte--izquierdo {
    position: absolute;
    height: 53.98mm;
    width: 6mm;
    left: 0;
    border: 1px solid black;
    border-width: 1px 0;
}
.carnetacae__margen {
    position: relative;
    height: 59.98mm;
    width: 91.6mm;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    z-index: 1;
}
.carnetacae__carnet {
    position: absolute;
    height: 53.98mm;
    width: 85.6mm;
    padding: 5mm;
}
.modal-content-scroll.carnets {
    overflow-x: auto;
}
.carnetacae__area-impresion {
    position: absolute;
    height: 53.98mm;
    width: 85.6mm;
    padding: 5mm;
    border: 1px solid red;
    z-index: 1;
}
.carnetacae__bg {
    background-color: #fff;
    width: 100%;
    height: 100%;
}
/* .carnetacae__content:has(.carnetacae__logo) {
    display: flex;
    justify-content: center;
    align-items: center;
}
*/
.carnetacae__logo {
    width: 24mm;
    height: 24mm;
}
.carnetacae__bg img {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    opacity: 0.1;
    filter: contrast(2);
}
.carnetacae__content {
    height: 100%;
    width: 100%;
    opacity: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.carnetacae__content--back {
    height: 100%;
    width: 100%;
    opacity: 1;
    display: flex;
    flex-direction: row-reverse;
    overflow: hidden;
    gap: 10mm;
    align-items: center;
    justify-content: center;
}
.carnetacae__grafico--superior {
    position: absolute;
    background-color: #fff;
    width: 100mm;
    height: 100mm;
    top: -75mm;
    right: -75mm;
    transform: rotate(45deg);
}
.carnetacae__grafico--inferior {
    position: absolute;
    background-color: #fff;
    width: 100mm;
    height: 100mm;
    bottom: -75mm;
    left: -75mm;
    transform: rotate(45deg);
}
.carnetacae__avatar {
    position: absolute;
    top: 0;
    right: 0;
}
.carnetacae__icono {
    width: 21mm;
    height: 20.5mm;
}
.carnetacae__icono * {
    fill: #fff !important;
}
.carnetacae__avatar--img {
    position: absolute;
    width: 15mm;
    height: 15mm;
    top: 0;
    right: 0;
    margin: 5mm 5mm 0 0;
    border-radius: 50mm;
    z-index: 1;
}
.carnetacae__italic {
    font-size: 7pt;
    font-weight: 300;
    font-style: italic;
    color: #3a3a3a;
}
.carnetacae__nombre {
    font-size: 9pt;
    font-weight: bold;
    color: #3a3a3a;
}
.carnetacae__dojo {
    font-size: 8pt;
    font-weight: 300;
    color: #3a3a3a;
}
.carnetacae__divisor {
    margin: 4px 0 2px 0;
    width: 15px;
    border-bottom: 0.25px solid #3a3a3a;
}
.carnetacae__flex-container {
    position: relative;
    margin-top: 1mm;
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex: 1 0 auto;
    align-items: flex-end;
    margin-top: 4mm;
}
.carnetacae__qr--container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.carnetacae__qr {
    position: relative;
    background-color: white;
    z-index: 1;
    width: 28mm;
    height: 28mm;
}
.carnetacae__kanji {
    height: 23mm;
    margin-right: 3mm;
    width: auto;
}
.carnetacae__kanji * {
    fill: #3a3a3a !important;
}
.carnetacae__button {
    position: relative;
    overflow: hidden;
}
.carnetacae__progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #7b032d;
    transition: all 1s;
}
.carnetacae__button-download {
    width: fit-content;
    height: fit-content;
    z-index: 1;
}
.carnetacae__button-download * {
    fill: #fff !important;
}
.carnetacae__button-text {
    line-height: 2rem;
    z-index: 1;
}
.modal-carnetacae .modal-container {
    width: fit-content !important;
    max-width: 90% !important;
    height: fit-content !important;
    min-height: unset;
    max-height: 90% !important;
    margin: 0 auto;
}
.modal-carnetacae .modal-container .modal-footer {
    margin-top: 0;
}
